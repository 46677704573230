import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Options = ({ label, options, selectedOptions, setSelectedOptions, isError, errorText }) => {
    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    return (
        <Row className="mb-3">
            <Col md={4}>
                <label>{label}</label>
            </Col>
            <Col md={8}>
                <Form>
                    {options.map((option, index) => (
                        <Form.Check
                            key={index}
                            type="checkbox"
                            id={`checkbox-${index}`}
                            label={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                            className="mb-2"
                            autoComplete='off'
                        />
                    ))}
                </Form>
                {isError && <small className="text-danger">{errorText}</small>}
            </Col>
        </Row>
    );
};

export default Options;
