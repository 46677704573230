import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomDropdown from './CustomDropdown';
import CheckBox from './checkBox';
import { countries } from './Countries';

const CountryContactDetails = ({ initialData, setRowsData, errors = {}, supportingText, countryList, label, checkLabel, name, isSingle, setIsSingle }) => {
    const [singleRow, setSingleRow] = useState(() => {
        return initialData[0] || { country: '', code: '', name: '', phoneNumber: '', email: '' };
    });
    const [rows, setRows] = useState(() => {
        return countryList ? countryList.map(country => {
            const existingRow = initialData.find(row => row.country === country);
            if (existingRow) return existingRow;
            const matchedCountry = countries.find(c => c.label === country);
            return { 
                country, 
                code: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                name: '', 
                phoneNumber: '', 
                email: '' 
            };
        }) : [];
    });

    const [formError, setFormError] = useState(''); // Add form error state

    const handleSingleChange = (isSingle) => {
        setIsSingle(isSingle);
        setFormError(''); // Clear the error when a valid option is selected
        if (isSingle) {
            const singleRowData = initialData[0] || { country: '', code: '', name: '', phoneNumber: '', email: '' };
            setSingleRow(singleRowData);
            setRowsData([singleRowData]);
        } else {
            const updatedRows = countryList.map(country => {
                const existingRow = initialData.find(row => row.country === country);
                if (existingRow) return existingRow;
                const matchedCountry = countries.find(c => c.label === country);
                return { 
                    country, 
                    code: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                    name: '', 
                    phoneNumber: '', 
                    email: '' 
                };
            });
            setRows(updatedRows);
            setRowsData(updatedRows);
        }
    };

    const handleSingleRowChange = (key, value) => {
        const updatedSingleRow = { ...singleRow, [key]: value };
        setSingleRow(updatedSingleRow);
        setRowsData([updatedSingleRow]);
    };

    const handleChange = (index, key, value) => {
        const updatedRows = [...rows];
        updatedRows[index][key] = value;
        setRows(updatedRows);
        setRowsData(updatedRows);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        setRowsData(updatedRows);
    };

    const displayError = (key, index) => errors[`${key}${index}`];

    const renderSingleRow = () => (
        <div className="contact-form-entry mb-3">
            <Row className="mb-3 align-items-center">
                <Col md={8} className="offset-md-4">
                    <Row>
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                value={singleRow.name}
                                onChange={(e) => handleSingleRowChange('name', e.target.value)}
                                className={displayError(`${name}_name_`, 0) ? 'border-danger' : ''}
                                autoComplete='off'
                            />
                            {displayError(`${name}_name_`, 0) && <small className="text-danger">*Please enter name</small>}
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={singleRow.email}
                                onChange={(e) => handleSingleRowChange('email', e.target.value)}
                                className={displayError(`${name}_email_`, 0) ? 'border-danger' : ''}
                                autoComplete='off'
                            />
                            {displayError(`${name}_email_`, 0) && <small className="text-danger">*Please enter email</small>}
                        </Col>
                        <Col md={3}>
                            <CustomDropdown
                                selectedValue={singleRow.code}
                                setSelectedValue={(value) => handleSingleRowChange('code', value)}
                                items={countries}
                                placeholder="Select Code"
                                getItemLabel={(item) => `${item.code} ${item.phone}`}
                                getItemValue={(item) => `${item.code} ${item.phone}`}
                                className={displayError(`${name}_code_`, 0) ? 'border-danger' : ''}
                            />
                            {displayError(`${name}_code_`, 0) && <small className="text-danger">*Please enter code</small>}
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                type="number"
                                placeholder="Enter Phone"
                                value={singleRow.phoneNumber}
                                onChange={(e) => handleSingleRowChange('phoneNumber', e.target.value)}
                                className={displayError(`${name}_phoneNumber_`, 0) ? 'border-danger' : ''}
                                autoComplete='off'
                            />
                            {displayError(`${name}_phoneNumber_`, 0) && <small className="text-danger">*Please enter phone number</small>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={8} className="offset-md-4">
                    <small className="form-text text-muted">{supportingText}</small>
                </Col>
            </Row>
        </div>
    );

    const renderMultipleRows = () => (
        rows.map((row, index) => (
            <div key={index} className="contact-form-entry mb-3">
                <Row className="mb-3 align-items-center">
                    <Col md={4}></Col>
                    <Col md={7}>
                        <h6>{row.country}</h6>
                    </Col>
                    <Col md={1} className="text-right">
                        <Button variant="link" onClick={() => handleRemoveRow(index)} className="p-0">
                            <img src={`${process.env.PUBLIC_URL}/images/delete.svg`} alt="Delete" style={{ width: '20px', height: '20px' }} />
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-3 align-items-center">
                    <Col md={8} className="offset-md-4">
                        <Row>
                            <Col md={3}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name"
                                    value={row.name}
                                    onChange={(e) => handleChange(index, 'name', e.target.value)}
                                    className={displayError(`${name}_name_`, index) ? 'border-danger' : ''}
                                    autoComplete='off'
                                />
                                {displayError(`${name}_name_`, index) && <small className="text-danger">*Please enter name</small>}
                            </Col>
                            <Col md={3}>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={row.email}
                                    onChange={(e) => handleChange(index, 'email', e.target.value)}
                                    className={displayError(`${name}_email_`, index) ? 'border-danger' : ''}
                                    autoComplete='off'
                                />
                                {displayError(`${name}_email_`, index) && <small className="text-danger">*Please enter email</small>}
                            </Col>
                            <Col md={3}>
                                <CustomDropdown
                                    selectedValue={row.code}
                                    setSelectedValue={(value) => handleChange(index, 'code', value)}
                                    items={countries}
                                    placeholder="Select Code"
                                    getItemLabel={(item) => `${item.code} ${item.phone}`}
                                    getItemValue={(item) => `${item.code} ${item.phone}`}
                                    className={displayError(`${name}_code_`, index) ? 'border-danger' : ''}
                                />
                                {displayError(`${name}_code_`, index) && <small className="text-danger">*Please enter code</small>}
                            </Col>
                            <Col md={3}>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Phone"
                                    value={row.phoneNumber}
                                    onChange={(e) => handleChange(index, 'phoneNumber', e.target.value)}
                                    className={displayError(`${name}_phoneNumber_`, index) ? 'border-danger' : ''}
                                    autoComplete='off'
                                />
                                {displayError(`${name}_phoneNumber_`, index) && <small className="text-danger">*Please enter phone number</small>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="offset-md-4">
                        <small className="form-text text-muted">{supportingText}</small>
                    </Col>
                </Row>
            </div>
        ))
    );

    return (
        <div className="contact-form">
            <Row className="mb-3 align-items-center">
                <Col md={4}>
                    <label>{checkLabel}</label>
                </Col>
                <Col md={8}>
                    <CheckBox
                        isChecked={isSingle}
                        setIsChecked={handleSingleChange}
                    />
                    {formError && <small className="text-danger">{formError}</small>} {/* Add form error display */}
                </Col>
            </Row>
            {isSingle !== null && (
                <>
                    <Row>
                        <Col md={4}>
                            <label>{label}</label>
                        </Col>
                        <Col md={8}></Col>
                    </Row>
                    {isSingle ? renderSingleRow() : renderMultipleRows()}
                </>
            )}
        </div>
    );
};

export default CountryContactDetails;
