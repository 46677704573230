import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import { API } from './API';

const FileChooser = ({ label, description, fileData = [], setFileData, isError, errorText, companyName, directory, companyNameGet }) => {
    const initialFileState = {
        localFile: null,
        localFileName: fileData[0]?.name || '',
        fileUrl: fileData.url || '',
        showDownloadLink: fileData.length > 0,
    };

    const [fileState, setFileState] = useState(initialFileState);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const handleFileChange = async (file) => {
        if (file && fileState.localFile && file.name === fileState.localFile.name) {
            setToastVariant('warning');
            setToastMessage('Cannot choose the same file twice');
            setShowToast(true);
            return;
        }

        const url = URL.createObjectURL(file);
        setFileState({
            localFile: file,
            localFileName: file.name,
            fileUrl: url,
            showDownloadLink: false,
        });
        setFileData([{ name: file.name, url: url }]);

        try {
            await uploadFileToServer(file, url);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleRemoveFile = () => {
        setFileState({
            localFile: null,
            localFileName: '',
            fileUrl: '',
            showDownloadLink: false,
        });
        setFileData([]);
    };

    const uploadFileToServer = async (file, url) => {
        const allowedExtensions = [
            'docx', 'pdf', 'pptx', 'xlsx', 'xls', 'doc', // Document types
            'txt', 'rtf', // Text types
            'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', // Image types
            'zip', 'rar', 'tar', 'gz', // Compressed types
            'mp3', 'wav', 'ogg', 'm4a', // Audio types
            'mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', // Video types
            'html', 'css', 'js', 'json', 'xml', // Web types
            'csv' // Data types
        ];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!allowedExtensions.includes(fileExtension)) {
            setToastMessage('You cannot upload a file of this type');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            const fileContent = e.target.result.split(',')[1];
    
            const dataToSave = {
                fileName: file.name,
                fileType: file.type,
                fileContent: fileContent,
                companyName: companyName,
            };
    
            // Show toast notification for uploading
            setToastMessage('Uploading file...');
            setToastVariant('info');
            setShowToast(true);
    
            try {
                const response = await API.post('/DMC/upload', dataToSave);
                console.log('response from server', response.status);
    
                if (response.data) {
                    setToastVariant('success');
                    setToastMessage('File uploaded successfully');
                    setShowToast(true);
                    setFileState(prevState => ({
                        ...prevState,
                        fileUrl: response.data.url,
                        showDownloadLink: true,
                    }));
                } else {
                    setToastVariant('danger');
                    setToastMessage('File upload unsuccessful');
                    setShowToast(true);
                }
            } catch (error) {
                setToastVariant('danger');
                setToastMessage('File upload unsuccessful');
                setShowToast(true);
            }
        };
        reader.readAsDataURL(file);
    };
    
    const handleDownload = async (companyName, directory, fileName) => {
        try {
            console.log(`file name is ${fileName}`);
            const response = await API.post('/DMC/getFile', {
                companyName,
                directory,
                fileName,
            });

            const { base64File } = response.data;

            // Create a blob from the base64 string
            const blob = new Blob([Uint8Array.from(atob(base64File), c => c.charCodeAt(0))], { type: 'application/octet-stream' });

            // Create a link to download the blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className="form-content">
            <Row>
                <Col md={4}>
                    <label>{label}</label>
                </Col>
                <Col md={8}>
                    <Row className="mb-2">
                        <Col md={8}>
                            {fileState.localFileName ? (
                                <>
                                    <p>
                                        <strong>File:&nbsp;</strong>
                                        {fileState.showDownloadLink && (
                                            <span
                                                className="file-link"
                                                style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }}
                                                onClick={() => handleDownload(companyNameGet, directory, fileState.localFileName)}
                                            >
                                                {fileState.localFileName.length>25?fileState.localFileName.slice(0,25)+'...':fileState.localFileName}
                                            </span>
                                        )}
                                        <span
                                            className="file-remove"
                                            style={{ color: 'red', textDecoration: 'none', cursor: 'pointer', marginLeft: '10px' }}
                                            onClick={handleRemoveFile}
                                        >
                                            &#x2715;
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleFileChange(e.target.files[0])}
                                    className="file-input"
                                    autoComplete='off'
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            {isError ? (
                                <small className="text-danger">{errorText}</small>
                            ) : (
                                <small className="wide-small">{description}</small>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Toast
    onClose={() => setShowToast(false)}
    show={showToast}
    delay={toastVariant === 'info' ? false : 3000} // No auto-hide for 'info' variant
    autohide={toastVariant !== 'info'} // Only auto-hide for success and danger variants
    style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}
>
    <Toast.Header style={{ 
        backgroundColor: 
            toastVariant === 'success' ? 'green' : 
            toastVariant === 'danger' ? 'red' : 
            toastVariant === 'info' ? 'orange' : 'orange',
        color: 'white' 
    }}>
        <strong className="mr-auto">Upload</strong>
    </Toast.Header>
    <Toast.Body style={{ 
        backgroundColor: 'white', 
        color: toastVariant === 'success' ? 'green' : 
               toastVariant === 'danger' ? 'red' : 
               toastVariant === 'info' ? 'orange' : 'orange'
    }}>
        {toastMessage}
    </Toast.Body>
</Toast>
        </div>
    );
};

export default FileChooser;
