import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSave } from './SaveContext';



const CheckBox = ({ text, isChecked, setIsChecked,isError,description}) => {
    const save = useSave();

    const handleOptionChange = (event) => {
        setIsChecked(event.target.value === "true");
    };

    useEffect(() => {
        save && save(() => {
            console.log("Saved value from CheckBox:", isChecked);
            // Additional save logic can go here
        });
    }, [save, isChecked]);

    return (
        <>
            <Col className="check-box" md={4}>
                <label>{text}</label>
            </Col>
            <Col className="radio-group d-flex align-items-center" md={8}>
                <label className="radio-option mx-2">
                    <input 
                        type="radio" 
                        value="true" 
                        checked={isChecked === true} 
                        onChange={handleOptionChange} 
                    />
                    Yes
                </label>
                <label className="radio-option mx-2">
                    <input 
                        type="radio" 
                        value="false" 
                        checked={isChecked === false} 
                        onChange={handleOptionChange} 
                    />
                    No
                </label>
                
            </Col>
            <Row>
            <Col md = {4}></Col>
            <Col md = {8}>
                {(isError && <small className="text-danger">*Please answer question</small>) || (<small>{description}</small>)}
                </Col>
            </Row>
            
        </>
    );
};

export default CheckBox;
