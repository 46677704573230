import { useState, useEffect } from 'react';
import CustomToast from './CustomToast.js'
import { API } from '../API.js'

const RequestLogin = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState({
    headerColor: '',
    headerMessage: '',
    bodyColor: '',
    bodyMessage: ''
  });
  const handleSubmit = async (event) => {
    setToastContent({
      headerColor: 'warning',
      headerMessage: 'Success',
      bodyColor: 'warning',
      bodyMessage: 'A one-time login link has been sent to your email address. Please check your mailbox to proceed.'
    })    
    event.preventDefault();
    try {
      await API.post('/DMC/send-login-link', { email });
      setToastContent({
        headerColor: 'success',
        headerMessage: 'Success',
        bodyColor: 'success',
        bodyMessage: 'A one-time login link has been sent to your email address. Please check your mailbox to proceed.'
      })
      setShowToast(true);
    } catch (error) {
      setToastContent({
        headerColor: 'danger',
        headerMessage: 'Error',
        bodyColor: 'danger',
        bodyMessage: 'Failed to send login link. Please reach out to partner@marcopolo-holidays.com'
      })
      setShowToast(true);      
    }
  };
  useEffect(() => {
    const isEmailValid = (email) => {
      if (email.length>0) {return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);}
      else {return true;}
    }    
    setIsEmailValid(isEmailValid(email));
  }, [email]);
  return (
    <div className="container d-flex justify-content-center align-items-center" style={{"height": '100vh'}}>
      <div className="row justify-content-center">
        <div className="col-md-6 d-flex align-items-center">
          <div className="w-100">
            <div className="card-body p-4 d-flex justify-content-center align-items-center flex-column">
              <img src={`${process.env.PUBLIC_URL}/images/transparent-logo.png`} alt="Login Illustration" className="w-50 mb-5" style={{"cursor": "pointer"}} onClick={() => { window.location.href = "https://www.marcopolo-holidays.com"; }} />
              <h2 className="text-center mb-4">Marcopolo Partners</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3" style={{width: '30vw'}}>
                  <input
                    type="email"
                    className={`form-control w-100 ${!isEmailValid ? 'is-invalid' : ''}`} // Conditional class
                    id="email"
                    placeholder="Enter Registered Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">Send Login Link</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomToast 
        show={showToast}
        onClose={() => setShowToast(false)}
        headerColor={toastContent.headerColor}
        headerMessage={toastContent.headerMessage}
        bodyColor={toastContent.bodyColor}
        bodyMessage={toastContent.bodyMessage}
      />
    </div>
  );
};

export default RequestLogin;
