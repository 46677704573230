import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomDropdown from './CustomDropdown';
import { countries } from './Countries';




const ContactFormWithAdd = ({label = 'Your details (Company Representative)', initialData, setRowsData, errors = {}, supportingText, placeholder1 = 'Enter Reference Agent Name', placeholder2 = 'Enter Company name(Mandatory)',disable = false }) => {
    const [rows, setRows] = useState(initialData.length ? initialData : [{ selectedPrefix: '', name: '', phoneNumber: '', email: '', designation: '' }]);
    const [localErrors, setLocalErrors] = useState(errors);

    useEffect(() => {
        setRowsData(rows);
    }, [rows, setRowsData]);

    useEffect(() => {
        setLocalErrors(errors);
    }, [errors]);

    const validateRows = (rows) => {
        const newErrors = {};
        rows.forEach((row, index) => {
            if (!row.designation) newErrors[`designation_${index}`] = 'Company name is required';
            
        });
        return newErrors;
    };

    const handleAddRow = () => {
        const newErrors = validateRows(rows);
        if (Object.keys(newErrors).length === 0) {
            const updatedRows = [...rows, { selectedPrefix: '', name: '', phoneNumber: '', email: '', designation: '' }];
            setRows(updatedRows);
            setLocalErrors({});
        } else {
            setLocalErrors(newErrors);
        }
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleChange = (index, key, value) => {
        const updatedRows = rows.map((row, i) => (i === index ? { ...row, [key]: value } : row));
        setRows(updatedRows);
        const newErrors = { ...localErrors };
        delete newErrors[`${key}_${index}`];
        setLocalErrors(newErrors);
    };

    const displayError = Object.keys(localErrors).length > 0;

    return (
        <div className="contact-form">
            {rows.map((row, index) => (
                <div key={index} className="contact-form-entry mb-3">
                    <Row className="mb-3 align-items-center">
                        <Col md={4}>
                            {index === 0 && <label>{label}</label>}
                        </Col>
                        <Col md={8}>
                            <Row className="mb-3">
                                <Col md={5}>
                                <Form.Control
                                        type="text"
                                        placeholder={placeholder2}
                                        value={row.designation}
                                        onChange={(e) => handleChange(index, 'designation', e.target.value)}
                                        autoComplete='off'
                                    />
                                    {localErrors[`designation_${index}`] && <small className="text-danger">{localErrors[`designation_${index}`]}</small>}
                                    
                                </Col>
                                <Col md={3}>
                                    <CustomDropdown
                                        selectedValue={row.selectedPrefix}
                                        setSelectedValue={(value) => handleChange(index, 'selectedPrefix', value)}
                                        items={countries}
                                        placeholder="Select Code"
                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                        getItemValue={(item) => item.phone}
                                    />
                                    {localErrors[`selectedPrefix_${index}`] && <small className="text-danger">{localErrors[`selectedPrefix_${index}`]}</small>}
                                </Col>
                                <Col md={4}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Phone"
                                        value={row.phoneNumber}
                                        onChange={(e) => handleChange(index, 'phoneNumber', e.target.value)}
                                        autoComplete='off'
                                    />
                                    {localErrors[`phoneNumber_${index}`] && <small className="text-danger">{localErrors[`phoneNumber_${index}`]}</small>}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter Email"
                                        value={row.email}
                                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                                        disabled = {disable ? true :false}
                                        autoComplete='off'
                                    />
                                    {localErrors[`email_${index}`] && <small className="text-danger">{localErrors[`email_${index}`]}</small>}
                                </Col>
                                <Col md={index >0 ?5:6}>
                                <Form.Control
                                        type="text"
                                        placeholder={placeholder1}
                                        value={row.name}
                                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                                        autoComplete='off'
                                    />
                                    {localErrors[`name_${index}`] && <small className="text-danger">{localErrors[`name_${index}`]}</small>}
                                </Col>
                                {index
                                 > 0 && (
                                    <Col md={1} className="d-flex align-items-center">
                                        <Button variant="link" onClick={() => handleRemoveRow(index)} className="p-0">
                                            <img src={`${process.env.PUBLIC_URL}/images/delete.svg`} alt="Delete" style={{ width: '20px', height: '20px' }} />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            {index === rows.length - 1 && displayError && (
                                <Row>
                                    <Col md={12}>
                                        <small className="text-danger">*Please enter Company Name</small>
                                    </Col>
                                </Row>
                            )}
                            {index === rows.length - 1 && !displayError && (
                                <Row>
                                    <Col md={12}>
                                        <small className="form-text text-muted">{supportingText}</small>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            ))}
            <Row>
                <Col md={{ span: 8, offset: 4 }} className="text-right">
                    <Button variant="outline-primary" onClick={handleAddRow}>
                        + Add
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ContactFormWithAdd;
