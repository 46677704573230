import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { countries } from './Countries.js';
import { API } from './API.js';


const CountriesWithCode = ({ showCountrySelector,companyNameGet, inputText, description, showControls, rowsData, setRowsData, errors = {}, companyName,directory }) => {
    const [rows, setRows] = useState(rowsData ? rowsData : [{ selectedCountry: '', name: 'No file chosen', url: '', uploadSuccess: null, duplicateFileError: false, expiryDate: '' }]);
    const [localErrors, setLocalErrors] = useState({});
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleDownload = async (companyName, directory, fileName) => {
        try {
          console.log(`file name is ${fileName}`);
            const response = await API.post('/DMC/getFile', {
                companyName,
                directory,
                fileName,
            });
      
            const { base64File } = response.data;
      
            // Create a blob from the base64 string
            const blob = new Blob([Uint8Array.from(atob(base64File), c => c.charCodeAt(0))], { type: 'application/octet-stream' });
      
            // Create a link to download the blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
      };
      

    useEffect(() => {
        if (rowsData && rowsData.length > 0) {
            setRows(rowsData);
        }
    }, [rowsData]);

    useEffect(() => {
        setLocalErrors(errors);
    }, [errors]);

    useEffect(() => {
        const removeAccents = (string) => {
            return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }        
        const filtered = countries.filter(country =>
            removeAccents(country.label).toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCountries(filtered);
    }, [searchTerm]);

    const validateRows = (rows) => {
        const newErrors = {};
        rows.forEach((row, index) => {
            if (showCountrySelector && !row.selectedCountry) {
                newErrors[`selectedCountry_${index}`] = '* Please select a country';
            }
            if (!row.name) {
                newErrors[`file_${index}`] = '* Please choose a file';
            }
           
        });
        return newErrors;
    };

    const addRow = () => {
        const newErrors = validateRows(rows);
        if (Object.keys(newErrors).length === 0) {
            const updatedRows = [...rows, { selectedCountry: '', name: 'No file chosen', url: '', uploadSuccess: null, duplicateFileError: false, expiryDate: '' }];
            setRows(updatedRows);
            setLocalErrors({});
            if (setRowsData) setRowsData(updatedRows);
        } else {
            setLocalErrors(newErrors);
        }
    };

    const handleCountryChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].selectedCountry = value;
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
    };

    const handleFileChange = async (index, file) => {
        const newRows = [...rows];
        const isDuplicateFile = newRows.some((row, rowIndex) => rowIndex !== index && row.name === file.name);

        if (isDuplicateFile) {
            newRows[index].duplicateFileError = true;
            newRows[index].uploadSuccess = null;
            setRows(newRows);
            setToastMessage('Cannot choose the same file');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }

        newRows[index].file = file;
        newRows[index].name = file ? file.name : 'No file chosen';
        newRows[index].duplicateFileError = false;

        if (file) {
            const fileBlob = new Blob([file], { type: file.type });
            const url = URL.createObjectURL(fileBlob);
            newRows[index].url = url; // Set temporary local URL
            setRows(newRows); // Update the state immediately with the temporary URL
            setRowsData(newRows);
            console.log('about to upload with url: ', newRows[index].url);
            console.log('all rows now are', newRows);
            await uploadFileToServer(file, index); // Upload the file to the server
        } else {
            newRows[index].url = '';
            console.log('im here no url');
            setRows(newRows);
        }
    };

    const uploadFileToServer = async (file, index) => {
        const allowedExtensions = [
            'docx', 'pdf', 'pptx', 'xlsx', 'xls', 'doc', // Document types
            'txt', 'rtf', // Text types
            'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', // Image types
            'zip', 'rar', 'tar', 'gz', // Compressed types
            'mp3', 'wav', 'ogg', 'm4a', // Audio types
            'mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', // Video types
            'html', 'css', 'js', 'json', 'xml', // Web types
            'csv' // Data types
        ];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!allowedExtensions.includes(fileExtension)) {
            setToastMessage('You cannot upload a file of this type');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            const fileContent = e.target.result.split(',')[1];
            const today = new Date();
            const fileExtension = file.name.split('.').pop();
            console.log('file extension is', fileExtension);
            const [month, day, year] = today.toLocaleDateString('en-US').split('/');
            const dataToSave = {
                fileName: file.name,
                fileType: file.type,
                fileContent: fileContent,
                companyName: companyName
            };
    
            const timeoutPromise = (ms, promise) => {
                return new Promise((resolve, reject) => {
                    const timeoutId = setTimeout(() => {
                        reject(new Error("Request timed out"));
                    }, ms);
                    promise.then(
                        (res) => {
                            clearTimeout(timeoutId);
                            resolve(res);
                        },
                        (err) => {
                            clearTimeout(timeoutId);
                            reject(err);
                        }
                    );
                });
            };
    
            // Show toast notification for uploading
            setToastMessage('Uploading file...');
            setToastVariant('info');
            setShowToast(true);
    
            try {
                const response = await timeoutPromise(10000, API.post('/DMC/upload', { ...dataToSave }));
                console.log('response from server', response.status);
    
                const newRows = [...rows];
                newRows[index].uploadSuccess = true;
                setRows(newRows);
    
                setToastMessage('File uploaded successfully');
                setToastVariant('success');
                setShowToast(true);
            } catch (error) {
                console.log('file error is ', error.message);
    
                const newRows = [...rows];
                newRows[index].uploadSuccess = false;
                setRows(newRows);
    
                setToastMessage('File upload unsuccessful');
                setToastVariant('danger');
                setShowToast(true);
            }
        };
        reader.readAsDataURL(file);
    };
    
    
    
    const handleRemoveFile = (index) => {
        const newRows = [...rows];
        newRows[index].file = null;
        newRows[index].name = 'No file chosen';
        newRows[index].url = '';
        newRows[index].uploadSuccess = null;
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
    };

    const handleSearchChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);
        setIsDropdownOpen(true);
    };

    const handleInputFocus = () => {
        setIsDropdownOpen(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownOpen(false);
        }, 100);
    };

    const handleClearSelection = (index) => {
        const newRows = [...rows];
        newRows[index].selectedCountry = '';
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
        setSearchTerm('');
    };

    const handleDateChange = (index, date) => {
        const newRows = [...rows];
        newRows[index].expiryDate = date;
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
    };

    const displayError = (key, index) => {
        return localErrors[`${key}_${index}`] || errors[`${key}_${index}`] || '';
    };

    return (
        <div className="form-content">
          <Toast
    onClose={() => setShowToast(false)}
    show={showToast}
    delay={toastVariant === 'info' ? false : 3000} // No auto-hide for 'info' variant
    autohide={toastVariant !== 'info'} // Only auto-hide for success and danger variants
    style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}
>
    <Toast.Header style={{ 
        backgroundColor: 
            toastVariant === 'success' ? 'green' : 
            toastVariant === 'danger' ? 'red' : 
            toastVariant === 'info' ? 'orange' : 'orange',
        color: 'white' 
    }}>
        <strong className="mr-auto">Upload</strong>
    </Toast.Header>
    <Toast.Body style={{ 
        backgroundColor: 'white', 
        color: toastVariant === 'success' ? 'green' : 
               toastVariant === 'danger' ? 'red' : 
               toastVariant === 'info' ? 'orange' : 'orange'
    }}>
        {toastMessage}
    </Toast.Body>
</Toast>


            <Row>
                <Col md={4}>
                    <label>{inputText}</label>
                </Col>
                <Col md={8}>
                    {rows.map((row, index) => (
                        <React.Fragment key={index}>
                            <Row className="mb-2">
                                {showCountrySelector && (
                                    <Col md={3}>
                                        <div className="custom-dropdown">
                                            <Form.Control
                                                type="text"
                                                placeholder="Select Country"
                                                value={row.selectedCountry || searchTerm}
                                                onChange={handleSearchChange}
                                                onFocus={handleInputFocus}
                                                onBlur={handleInputBlur}
                                                className="custom-input"
                                                autoComplete='off'
                                            />
                                            {row.selectedCountry && (
                                                <div className="clear-button" onClick={() => handleClearSelection(index)}>
                                                    &times;
                                                </div>
                                            )}
                                            {isDropdownOpen && (
                                                <div className="dropdown-menu show custom-dropdown-menu">
                                                    {filteredCountries.map((country, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="custom-dropdown-item"
                                                            onMouseDown={() => handleCountryChange(index, country.label)}
                                                            onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                                            onMouseLeave={(e) => e.target.style.backgroundColor = ''}
                                                        >
                                                            {country.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                )}
                                <Col md={3}>
                                <Col md = {3}>
                                    <DatePicker
                                        selected={row.expiryDate}
                                        onChange={(date) => handleDateChange(index, date)}
                                        className={`mb-2 `}
                                        placeholderText="Expiry Date,if any"
                                        dateFormat="MMMM d, yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        minDate={new Date()} 
                                        autoComplete='off'
                                    />
                                    </Col>
                                    {row.expiryDate && (
                                        <button
                                        type="button"
                                        onClick={(date) => handleDateChange(index,null)}
                                        
                                        >
                                        x
                                        </button>
                                    )}
                                </Col>
                                <Col md={6}>
                                {row.uploadSuccess && row.url && row.name ? (
                                        <>
                                            <p>
                                                <strong>File:&nbsp;</strong>
                                                <span
                                                    className="file-link"
                                                    style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => handleDownload(companyNameGet,directory, row.name)}
                                                >
                                                    {row.name.length>25?row.name.slice(0,25)+'...':row.name}
                                                </span>
                                                <span
                                                    className="file-remove"
                                                    style={{ color: 'red', textDecoration: 'none', cursor: 'pointer', marginLeft: '10px' }}
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    &#x2715;
                                                </span>
                                            </p>
                                        </>
                                    ) : (
                                        <Form.Control
                                            type="file"
                                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                                            className="file-input"
                                            autoComplete='off'
                                        />
                                    )}
                                
                                        
                                </Col>
                                {showControls && index > 0 && (
                                    <Col md={1}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/delete.svg`}
                                            alt="Delete"
                                            className="delete-icon"
                                            onClick={() => handleDeleteRow(index)}
                                            style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </React.Fragment>
                    ))}
                    <Row className="mb-2">
                        <Col md={10}>
                            {Object.keys(localErrors).filter(key => key.startsWith('selectedCountry') || key.startsWith('file') || key.startsWith('expiryDate')).map((key, idx) => (
                                <small key={idx} className="text-danger d-block">{localErrors[key]}</small>
                            ))}
                            {!Object.keys(localErrors).length && <small className="wide-small">{description}</small>}
                        </Col>
                    </Row>
                    {showControls && (
                        <Row>
                            <Col md={2}>
                                <Button onClick={addRow} variant="outline-primary">+ Add</Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default CountriesWithCode;
