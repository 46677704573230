import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNavbar from './Navbar'; // Assuming CustomNavbar is your navigation component

const ThankYou = () => {

  return (
    <>
      <CustomNavbar centerText="" rightText="" />
      <Container className="my-5">
        <Row className="pb-10 d-flex justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/images/handshake.svg`} alt="Thank you" style={{ width: '200px', height: '200px' }} />
        </Row>
        <Row className="my-5 text-center">
          <Col>
            <h1 className="display-4 mb-4">Thank you, for completing our DMC Partner Registration</h1> 
            <p className="lead">We will contact you, should we require any additional information. Please find the email sent to your inbox for instructions on how to keep your company profile updated with us.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ThankYou;
