import React from 'react';
import Navbar from 'react-bootstrap/Navbar';


const CustomNavbar = ({ centerText, rightText }) => {
    return (
        <Navbar className="navbar-custom" sticky='top'>
            <Navbar.Brand href="/partner-form">
                <img
                    src={`${process.env.PUBLIC_URL}/images/marcopoloLogo.png`}
                    width="120"
                    height="auto"
                    className="d-inline-block align-top"
                    alt="Marcopolo logo"
                    style={{ marginLeft: '10px' }}
                />
            </Navbar.Brand>
            <div className="navbar-center d-none d-md-block">
                <div className="navbar-center-text">
                    {centerText}
                </div>
            </div>
            <div className="navbar-right">
                <div className="navbar-right-text">
                    {rightText}
                </div>
            </div>
        </Navbar>
    );
};

export default CustomNavbar;
