import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const Range = ({ label, description, initialValue = 0, setPercentage, isError, errorText }) => {
    const [value, setValue] = useState(initialValue);
    const [localError, setLocalError] = useState(false);

    useEffect(() => {
        setLocalError(isError);
    }, [isError]);

    const handleSliderChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        setPercentage(newValue);
        if (newValue === '' || newValue === null) {
            setLocalError(true);
        } else {
            setLocalError(false);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        if (newValue >= 0 && newValue <= 100) {
            setValue(newValue);
            setPercentage(newValue);
            setLocalError(false);
        } else {
            setLocalError(true);
        }
    };

    const data = {
        labels: ['FIT', 'Groups'],
        datasets: [
            {
                data: [value, 100 - value],
                backgroundColor: ['#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB'],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        animation: {
            duration: 0, // Disable animation
        },
    };

    return (
        <div className="range-component">
            <Row className="align-items-center mb-3">
                <Col md={4}>
                    <label>{label}</label>
                </Col>
                <Col md={8} className="d-flex align-items-center">
                    <Form.Control
                        type="number"
                        min="0"
                        max="100"
                        value={value}
                        onChange={handleInputChange}
                        className="number-input ml-2"
                        autoComplete='off'
                    />
                    <Form.Control
                        type="range"
                        min="0"
                        max="100"
                        value={value}
                        onChange={handleSliderChange}
                        className="slider custom-slider"
                        autoComplete='off'
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}></Col>
                <Col md={8}>
                    {localError ? (
                        <small className="text-danger">{errorText}</small>
                    ) : (
                        <small className="form-text text-muted">{description}</small>
                    )}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 8, offset: 2 }} style={{ textAlign: 'center' }}>
                    <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
                        <Pie data={data} options={options} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Range;
