import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const animatedComponents = makeAnimated();

const MultiSelectDropdown = ({
    inputText,
    description,
    selectedCountries,
    setSelectedCountries,
    options,
    isError,
    errorText,
    rows = [],
    setRows,
    maxSelectLimit = -1,
    placeholder = "Select Countries",
    enableAdditionalFunctionality = false
}) => {
    const [selectError, setSelectError] = useState('');
    const [percentages, setPercentages] = useState([]);
    const [percentageError, setPercentageError] = useState('');
    const [totalError, setTotalError] = useState('');

    useEffect(() => {
        if (enableAdditionalFunctionality && rows.length > 0) {
            const initialPercentages = rows.map(row => row.percentage);
            setPercentages(initialPercentages);
        } else {
            setPercentages([]);
        }
    }, [enableAdditionalFunctionality, rows.length]);

    useEffect(() => {
        if (enableAdditionalFunctionality) {
            const selectedCountryLabels = selectedCountries.map(option => option.label);
            const newRows = selectedCountryLabels.map(label => ({
                country: label,
                percentage: rows.find(row => row.country === label)?.percentage ?? null
            }));
            if (JSON.stringify(newRows) !== JSON.stringify(rows)) {
                setRows(newRows);
            }
        }
    }, [selectedCountries, enableAdditionalFunctionality, rows, setRows]);

    const handleChange = (selectedOptions) => {
        if (maxSelectLimit !== -1 && selectedOptions.length > maxSelectLimit) {
            setSelectError(`You can only select up to ${maxSelectLimit} options.`);
            return;
        } else {
            setSelectError('');
        }

        const selectedCountryLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];

        const newRows = selectedCountryLabels.map(label => ({
            country: label,
            percentage: rows.find(row => row.country === label)?.percentage ?? null
        }));

        if (enableAdditionalFunctionality) setRows(newRows);
        setSelectedCountries(selectedOptions || []);

        if (enableAdditionalFunctionality) {
            const initialPercentages = newRows.map(row => row.percentage);
            setPercentages(initialPercentages);
        }
    };

    const handlePercentageChange = (index, value) => {
        const newRows = [...rows];
        const newPercentage = parseFloat(value) || null;
        newRows[index].percentage = newPercentage;

        const totalPercentage = newRows.reduce((acc, row) => acc + (row.percentage || 0), 0);

        if (totalPercentage > 100) {
            setPercentageError('Total percentage cannot exceed 100%');
            setTotalError('');
        } else if (totalPercentage < 100 && newRows.every(row => row.percentage !== null)) {
            setPercentageError('');
            setTotalError('Total percentage must equal 100%');
        } else {
            setPercentageError('');
            setTotalError('');
        }

        setRows(newRows);
        setPercentages(newRows.map(row => row.percentage));
    };

    const chartData = enableAdditionalFunctionality ? {
        labels: rows.map(row => row.country),
        datasets: [
            {
                label: 'Country Percentages',
                data: percentages,
                backgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
                ]
            }
        ]
    } : null;

    const pieChartOptions = {
        animation: {
            duration: 0, // Disable animation
        }
    };

    const shouldShowPieChart = percentages.some(percentage => percentage !== null && percentage > 0) && !percentageError && !totalError;

    return (
        <div className="form-content">
            <Row>
                <Col md={4}>
                    <label>{inputText}</label>
                </Col>
                <Col md={8}>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        value={selectedCountries}
                        onChange={handleChange}
                        placeholder={placeholder}
                        className="multi-select-dropdown"
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={false}
                        styles={{
                            input: (provided) => ({
                                ...provided,
                                autoComplete: 'off',
                            }),
                            control: (provided) => ({
                                ...provided,
                                boxShadow: 'none',
                                '&:hover': { border: 'none' },
                                minHeight: '35px'
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                                transition: 'opacity 0.2s ease',
                                opacity: 1,
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                maxHeight: '200px',
                                overflowY: 'auto',
                                WebkitOverflowScrolling: 'touch',
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                backgroundColor: '#e2e3e5',
                                borderRadius: '10px',
                                padding: '2px 5px',
                            }),
                            multiValueLabel: (provided) => ({
                                ...provided,
                                color: '#495057'
                            }),
                            multiValueRemove: (provided) => ({
                                ...provided,
                                color: '#6c757d',
                                cursor: 'pointer',
                                ':hover': {
                                    color: '#495057'
                                }
                            })
                        }}
                    />
                    <Col md={12}>
                        {isError || selectError ? (
                            <small className="text-danger">{isError ? errorText : selectError}</small>
                        ) : (
                            <small className="wide-small">{description}</small>
                        )}
                    </Col>
                </Col>
            </Row>
            {enableAdditionalFunctionality && rows.length > 0 && (
                <div>
                    {rows.map((row, index) => (
                        <Row key={index} className="mt-2">
                            <Col md={4}>
                                <label>{row.country}:</label>
                            </Col>
                            <Col md={8}>
                                <input
                                    type="number"
                                    value={row.percentage !== null ? row.percentage : ''}
                                    onChange={(e) => handlePercentageChange(index, e.target.value)}
                                    className="form-control"
                                    placeholder="Approximate Percentage"
                                    min="0"
                                    max="100"
                                    autoComplete='off'
                                />
                            </Col>
                        </Row>
                    ))}
                    {percentageError && (
                        <Row>
                            <Col md={12}>
                                <small className="text-danger">{percentageError}</small>
                            </Col>
                        </Row>
                    )}
                    {totalError && (
                        <Row>
                            <Col md={12}>
                                <small className="text-danger">{totalError}</small>
                            </Col>
                        </Row>
                    )}
                    {shouldShowPieChart && (
                        <Row className="mt-4">
                            <Col md={{ span: 8, offset: 2 }} style={{ textAlign: 'center' }}>
                                <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
                                    <Pie data={chartData} options={pieChartOptions} />
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
