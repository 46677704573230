import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Toast from 'react-bootstrap/Toast';

import SingleSelect from './SingleSelect';
import { API } from './API';

function isValidUrl(url) {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
}

const FileOrUrl = ({ label, initialData = [], setRowsData, errors = {}, companyNameGet, companyName, selectService, name, yesNo, setYesNo, countries, directory, wantsTo, setWantsTo, question }) => {
    const checkIfUrlEmpty = () => {
        if (initialData[0]?.url) {
            return true;
        }
        return false;
    };

    const [urlLink, setUrlLink] = useState(checkIfUrlEmpty);
    const [rows, setRows] = useState(initialData.length ? initialData : [{ country: '', service: '', file: '', start: '', end: '', url: '', fileName: '', fileUrl: '' }]);
    const [localErrors, setLocalErrors] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const [errorUpload, setErrorUpload] = useState(null);

    useEffect(() => {
        const newLocalErrors = {};
        Object.keys(errors).forEach(key => {
            if (key.startsWith(`${name}_`)) {
                newLocalErrors[key] = errors[key];
            }
        });
        setLocalErrors(newLocalErrors);
    }, [errors, name]);

    const validateRow = (row, index) => {
        let newErrors = {};
        if (yesNo === true && wantsTo === true && selectService && !row.service) {
            newErrors[`service_${index}`] = '*Service is required';
        }
        if (yesNo === true && wantsTo === true && urlLink && row.url && !isValidUrl(row.url)) {
            newErrors[`url_${index}`] = '*Invalid URL';
        }
        if (yesNo === true && wantsTo === true && !urlLink && !row.fileName) {
            newErrors[`fileName_${index}`] = '*File is required';
        }
        if (yesNo === true && wantsTo === true && !row.start) {
            newErrors[`start_${index}`] = '*Start Date is required';
        }
        if (yesNo === true && wantsTo === true && !row.end) {
            newErrors[`end_${index}`] = '*End Date is required';
        }
        if (yesNo === true && wantsTo === true && !row.country) {
            newErrors[`country_${index}`] = '*Country is required';
        }
        return newErrors;
    };

    const validateRows = (rows) => {
        const newErrors = {};
        rows.forEach((row, index) => {
            const rowErrors = validateRow(row, index);
            Object.assign(newErrors, rowErrors);
        });
        setLocalErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddRow = () => {
        if (validateRows(rows)) {
            setRows([...rows, { country: '', service: '', file: '', start: '', end: '', url: '', fileName: '', fileUrl: '' }]);
            setLocalErrors({});
        }
    };

    const handleChange = (index, key, value) => {
        const newRows = [...rows];
        newRows[index][key] = value;
        setRows(newRows);
        setRowsData(newRows);
        setLocalErrors((prevErrors) => {
            const { [`${key}_${index}`]: _, ...rest } = prevErrors;
            return rest;
        });
    };

    const handleDownload = async (companyName, directory, fileName) => {
        try {
            console.log(`file name is ${fileName}`);
            const response = await API.post('/DMC/getFile', {
                companyName,
                directory,
                fileName,
            });

            const { base64File } = response.data;

            // Create a blob from the base64 string
            const blob = new Blob([Uint8Array.from(atob(base64File), c => c.charCodeAt(0))], { type: 'application/octet-stream' });

            // Create a link to download the blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleRemoveRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
        setRowsData(newRows);
    };

    const handleRadioChange = (value) => {
        setYesNo(value);
        if (value === false) {
            setUrlLink(false);
            setWantsTo(false);
            setRowsData([{ country: '', service: '', file: '', start: null, end: null, url: '', fileName: '', fileUrl: '' }]);
            setRows([{ country: '', service: '', file: '', start: null, end: null, url: '', fileName: '', fileUrl: '' }]);
            setLocalErrors({});
        } else if (value === true) {
            if (rows.length === 0) {
                setRows([{ country: '', service: '', file: '', start: '', end: '', url: '', fileName: '', fileUrl: '' }]);
                setRowsData([{ country: '', service: '', file: '', start: '', end: '', url: '', fileName: '', fileUrl: '' }]);
            }
            validateRows(rows); // Trigger validation immediately
        }
    };

    const handleWantsToChange = (value) => {
        setWantsTo(value);
        if (value === false) {
            setLocalErrors({});
        } else {
            validateRows(rows); // Trigger validation immediately
        }
    };

    const handleCheckboxChange = (value) => {
        setUrlLink(value);
    };

    const handleFileChange = async (file, index) => {
        if (file && rows[index].file && file.name === rows[index].file.name) {
            setToastVariant('warning');
            setToastMessage('Cannot choose the same file twice');
            setShowToast(true);
            return;
        }

        const newRows = [...rows];
        newRows[index].file = file;
        setRows(newRows);

        try {
            await uploadFileToServer(file, index);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const uploadFileToServer = async (file, index) => {
        const allowedExtensions = [
            'docx', 'pdf', 'pptx', 'xlsx', 'xls', 'doc', // Document types
            'txt', 'rtf', // Text types
            'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', // Image types
            'zip', 'rar', 'tar', 'gz', // Compressed types
            'mp3', 'wav', 'ogg', 'm4a', // Audio types
            'mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', // Video types
            'html', 'css', 'js', 'json', 'xml', // Web types
            'csv' // Data types
        ];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!allowedExtensions.includes(fileExtension)) {
            setToastMessage('You cannot upload a file of this type');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            const fileContent = e.target.result.split(',')[1];
            const blob = new Blob([fileContent], { type: file.type });
            const blobUrl = URL.createObjectURL(blob);
    
            try {
                console.log('file name is ', file.name);
                const formData = {
                    fileName: file.name,
                    fileType: file.type,
                    fileContent: fileContent, // or blob if you need Blob data
                    companyName: companyName,
                };
    
                // Show toast notification for uploading
                setToastMessage('Uploading file...');
                setToastVariant('info');
                setShowToast(true);
    
                const response = await API.post('/DMC/upload', formData);
    
                const data = await response.data;
                if (data) {
                    const newRows = [...rows];
                    newRows[index].fileName = file.name;
                    newRows[index].fileUrl = blobUrl;
    
                    setRows(newRows);
                    setRowsData(newRows);
    
                    setToastVariant('success');
                    setToastMessage('File uploaded successfully');
                    setShowToast(true);
                    setErrorUpload(false);
                } else {
                    setToastVariant('danger');
                    setToastMessage('File upload unsuccessful');
                    setShowToast(true);
                    setErrorUpload(true);
                }
            } catch (error) {
                setToastVariant('danger');
                setToastMessage('File upload unsuccessful');
                setShowToast(true);
                setErrorUpload(true);
            }
        };
        reader.readAsDataURL(file);
    };
    

    const handleCountryChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].country = value;
        setRows(updatedRows);
    };

    const handleRemoveFile = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].fileName = null;
        setRows(updatedRows);
    };

    const displayError = (key, index) => localErrors[`${name}_${key}_${index}`];

    return (
        <div className="data-entry-component">
            <Row className="mb-3">
                <Col md={4}><label>{label}</label></Col>
                <Col md={8}>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        checked={yesNo === true}
                        onChange={() => handleRadioChange(true)}
                        inline
                        autoComplete='off'
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        checked={yesNo === false}
                        onChange={() => handleRadioChange(false)}
                        inline
                        autoComplete='off'
                    />
                    
                    {localErrors.yesNo && <small className="text-danger">{localErrors.yesNo}</small>}
                </Col>
            </Row>
            {yesNo === true && (
                <>
                    <Row className="mb-3">
                        <Col md={4}><label>{question}</label></Col>
                        <Col md={8}>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                checked={wantsTo === true}
                                onChange={() => handleWantsToChange(true)}
                                inline
                                autoComplete='off'
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                checked={wantsTo === false}
                                onChange={() => handleWantsToChange(false)}
                                inline
                                autoComplete='off'
                            />
                            <Form.Check
                                type="checkbox"
                                label="URL/Link"
                                checked={urlLink}
                                onChange={(e) => handleCheckboxChange(e.target.checked)}
                                inline
                                disabled={wantsTo !== true}
                                autoComplete='off'
                            />
                        </Col>
                    </Row>
                    {wantsTo === true && (
                        <>
                            {rows.map((row, index) => (
                                <div key={index} className="data-entry-row mb-3">
                                    <Row className="mb-3 ">
                                        <Col md={4}></Col>
                                        <Col md={4}>
                                            <SingleSelect
                                                inputText=""
                                                placeholder={"Select Country"}
                                                selectedValue={row.country}
                                                setSelectedValue={(value) => handleCountryChange(index, value)}
                                                options={countries}
                                                isError={displayError('country', index)}
                                                errorText={displayError('country', index)}
                                                removeLabel={true}
                                            />
                                        </Col>
                                        <Col md={selectService ? 4 : 0}>
                                            {selectService && (
                                                <Form.Control
                                                    as="select"
                                                    value={row.service || ''}
                                                    onChange={(e) => handleChange(index, 'service', e.target.value)}
                                                    className={`mb-2 ${displayError('service', index) ? 'is-invalid' : ''}`}
                                                    autoComplete='off'
                                                >
                                                    <option value="" disabled>
                                                        Select Service
                                                    </option>
                                                    <option value="Hotels">Hotels</option>
                                                    <option value="Transportation">Transportation</option>
                                                    <option value="Private tours">Private tours</option>
                                                    <option value="SIC tours">SIC Tours</option>
                                                    <option value="Airport transfers">Airport transfers</option>
                                                </Form.Control>
                                            )}
                                            {displayError('service', index) && (
                                                <small className="text-danger">{displayError('service', index)}</small>
                                            )}
                                        </Col>

                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            {!urlLink && (
                                                <>
                                                    {row.fileName && !errorUpload ? (
                                                        <p>
                                                            <strong>File:</strong>
                                                            <span
                                                                className="file-link"
                                                                style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }}
                                                                onClick={() => handleDownload(companyNameGet, directory, row.fileName)}
                                                            >
                                                                {row.fileName}
                                                            </span>
                                                            <span
                                                                className="file-remove"
                                                                style={{ color: 'grey', textDecoration: 'underline', cursor: 'pointer', marginLeft: '10px' }}
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                X
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <Row>
                                                            <Col md={9}>
                                                                <Form.Control
                                                                    type="file"
                                                                    placeholder={row.fileName}
                                                                    onChange={(e) => handleFileChange(e.target.files[0], index)}
                                                                    className={`mb-2 ${displayError('fileName', index) ? 'is-invalid' : ''}`}
                                                                />
                                                            </Col>
                                                           
                                                        </Row>
                                                    )}
                                                    {displayError('fileName', index) && (
                                                        <small className="text-danger">{displayError('fileName', index)}</small>
                                                    )}
                                                </>
                                            )}
                                            {urlLink && (
                                                <>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="example.com"
                                                        value={row.url}
                                                        onChange={(e) => handleChange(index, 'url', e.target.value)}
                                                        className={`mb-2 ${displayError('url', index) ? 'is-invalid' : ''}`}
                                                        autoComplete='off'
                                                    />
                                                    {displayError('url', index) && (
                                                        <small className="text-danger">{displayError('url', index)}</small>
                                                    )}
                                                </>
                                            )}
                                            <div className="no-move">
                                                <DatePicker
                                                    selected={row.start}
                                                    onChange={(date) => handleChange(index, 'start', date)}
                                                    className={`mb-2 ${displayError('start', index) ? 'is-invalid' : ''} fixed-element`}
                                                    placeholderText="Start Date"
                                                    dateFormat="MMMM d, yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='off'
                                                />
                                                {displayError('start', index) && (
                                                    <small className="text-danger">{displayError('start', index)}</small>
                                                )}
                                                <DatePicker
                                                    selected={row.end}
                                                    onChange={(date) => handleChange(index, 'end', date)}
                                                    className={`mb-2 ${displayError('end', index) ? 'is-invalid' : ''} fixed-element`}
                                                    placeholderText="End Date"
                                                    dateFormat="MMMM d, yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    disabled={row.start ? false : true}
                                                    minDate={row.start}
                                                    autoComplete='off'
                                                />
                                                {displayError('end', index) && (
                                                    <small className="text-danger">{displayError('end', index)}</small>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    {index > 0 && (
                                        <Row className="mb-3 align-items-center">
                                            <Col md={4}></Col>
                                            <Col md={8} className="text-right">
                                                <Button variant="link" onClick={() => handleRemoveRow(index)} className="p-0">
                                                    <img src={`${process.env.PUBLIC_URL}/images/delete.svg`} alt="Delete" style={{ width: '20px', height: '20px' }} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ))}
                            <Row>
                                <Col md={{ span: 8, offset: 4 }}>
                                    <Button variant="outline-primary" onClick={handleAddRow}>
                                        + Add
                                    </Button>
                                </Col>
                            </Row>
                            {Object.keys(localErrors).length > 0 && (
                                <Row className="mt-3">
                                    <Col md={{ span: 8, offset: 4 }}>
                                        <ul className="text-danger">
                                            {Object.values(localErrors).map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </>
            )}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={toastVariant === 'info' ? false : 3000} // No auto-hide for 'info' variant
                autohide={toastVariant !== 'info'} // Only auto-hide for success and danger variants
                style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}
            >
                <Toast.Header style={{ 
                    backgroundColor: 
                        toastVariant === 'success' ? 'green' : 
                        toastVariant === 'danger' ? 'red' : 
                        toastVariant === 'info' ? 'orange' : 'orange',
                    color: 'white' 
                }}>
                    <strong className="mr-auto">Upload</strong>
                </Toast.Header>
                <Toast.Body style={{ 
                    backgroundColor: 'white', 
                    color: toastVariant === 'success' ? 'green' : 
                           toastVariant === 'danger' ? 'red' : 
                           toastVariant === 'info' ? 'orange' : 'orange'
                }}>
                    {toastMessage}
                </Toast.Body>
            </Toast>
        </div>
    );
};

export default FileOrUrl;
