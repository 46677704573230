import axios from "axios";
export const API = axios.create({
    baseURL: "https://app.marcopolo-holidays.net/v1",
    // baseURL: "http://localhost:5000",
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  });
  