import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Toast, Button as BootstrapButton,ToastContainer } from 'react-bootstrap';
import CountriesWithCode from './CountriesWithCode.js';
import Text_input from './Text_input.js';
import CheckBox from './checkBox.js';
import ContactForm from './ContactForm.js';
import Handles from './Handles.js';
import CustomNavbar from './Navbar.js';
import Ellipse from './Ellipse.js';
import TwoText from './text2.js';
import MultiSelectDropdown from './MultiSelect.js';
import SingleSelect from './SingleSelect.js';
import { countries } from './Countries.js';
import FileChooser from './FileChooser.js';
import Options from './Options.js';
import Range from './Range.js';
import languages from './Languages.js';
import CountryTextInput from './CountryTextInput.js';
import ContactFormWithAdd from './ContactFormWithAdd.js';
import ContactDetails from './ContactDetails.js';
import CountryContactDetails from './CountryContactDetails.js';
import OwnOfficeSetupDetails from './OwnOfficeSetup.js';
import DaysOptions from './DaysOptions.js';
import FileOrUrl from './FileOrUrl.js';
import MultiWithFile from './MultiSelectWithFile.js';
import OfficeAddressInput from './components/OfficeAddressInput.js';
import { API } from './API.js';
import { set } from 'date-fns';

function MainForm() {
    let stagesCompleted = new Array(5).fill(false);
    const [currentStage, setCurrentStage] = useState(1);
    const [showToast, setShowToast] = useState(false);
    const [cookies] = useCookies(['access_token']);
    const [userEmail,setUserEmail] = useState('');
    const [showConfirmationToast, setShowConfirmationToast] = useState(false);
    const [moveToStage, setMoveToStage] = useState(0);
    const [errors, setErrors] = useState({});

    const handleToastConfirmation = async (confirm) => {
        setShowConfirmationToast(false);
        if (confirm) {
          await handleSubmit(moveToStage);
        }
    };
    
    const navigate = useNavigate();    

    useEffect(() => {
        fetchProfileData();
        // console.log('hi');
    }, []); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        setShowConfirmationToast(false);
        // Scroll to the top whenever the stage changes
        if ('scrollTo' in window) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            // Fallback code for browsers that do not support window.scrollTo
            window.scroll(0, 0);
        }
      }, [currentStage]);    


    function isValidUrl(url) {
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
        return urlRegex.test(url);
    }

    function isEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    /* 
    ||||||||||||||||||||||||||||||||||PART 1|||||||||||||||||||||||||||||||||||||||||
    THIS PART IS FOR THE DATA TYPES AND SAVING THEM INTO 5 DIFF STAGES THEN WE SAVE ALL OF THAT INTO ONE BIG SCHEMA 
    */
    const [basicCompanyInfo, setBasicCompanyInfo] = useState({
        data:{
            id: '',
            yearOfIncorporation: null,
            companyName: '',
            isFamilyRun: null,
            companyRepresentativeName: '',
            phoneCode: '',
            phoneNumber: '',
            email: '',
            designation: '',
            companyOwners : [{ code: '', name: '', phoneNumber: '', email: '' }],
            areCompanyOwnersInvolved : null,
            ownersInvolved: [],
            platforms: [{ platform: '', url: '' }],
            companyWebsite: ''
        },
        metadata:{
            createdBy: '',
            createdDate: '',
            updatedBy: '',
            updatedDate: ''
        }
    });

    const [companyProfileStage, setCompanyProfileStage] = useState({
        data:{
        isMemberOfAnyLocalOrInternatonalTravelIndustryAss: null,
        travelIndustryAssociations: [
            {
                association: '',
                membershipStartYear: '' // Initialize as an empty string to be consistent with text inputs
            }
        ],
        businessLicense: [
        {
            
        }
        ],
        countriesProvideTour: [], // Initialize as an empty array
        countriesOwnOffices: [], // Initialize as an empty array
        numFullTimeEmployees: '', // Initialize as an empty string
        handledUAEGCC: null,
        GCCCountries: [], // Initialize as an empty array
        operatesB2B: null,
        registrationGuidelines: '', // Initialize as an empty string for consistency
        bookingsPast5Years: null,
        companyInfo: '', // Initialize as an empty string
    },
    metadata:{
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: ''
    }
    });

    const [servicesAndOperations,setServicesAndOperations] = useState({
        data:{
        segmentsCaterTo :[],
        sourceMarketsIncoming : [],
        sourceMarketsPercentages: [],
        supplyToTravelAgentsUAE: null,
        UAETravelAgents: [
            {}
        ],
        providesSIC: null,
        acceptsFIT: null,
        percentageIsFIT: '',
        partnershipsWithSIC: null,
        countriesBookSICToursThirdParty: [],
        tourGuideLanguages: [],
        maintainOwnVehicleFleet: null,
        vehicleDetails: '',
        provideVisaRelatedServices: null,
        visaCountryAndDescription: [],
        officesOperateOutboundTravel: null,
        officesOperatedOutbound: []
        },
        metadata:{
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: ''
        }
    });

    const [contactDetails,setContactDetails] = useState({
        data:{
    
        // singleContactQuote: false,
        contactDetailsQuotes: [],
        // singleEscalationPointOfContact: false,
        escalationPointsOfContact: [],
        // singlePointFinance: false,
        financeOrAccountingDetails: [],
        officeSetupDetailsDuringOfficeHours:[],
        officeSetupDetailsAfterOfficeHours: [],
        workingDays : [],
        bankDetails: '',
        canAcceptPaymentsCreditCard: null,
        isSingle: null,
        isSingle1: null,
        isSingle2: null,
        sameContact: null,
        readyMadePackages: [{}],
        },
        metadata:{
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: ''
        }
    })

    const [packagesAndTariff, setPackagesAndTariff] = useState({
        data: {
            hasReadyMadePackages: null,
            readyMadePackages: [{}],
            hasItemizedTariffSheets: null,
            itemizedTariffSheets: [{}],
            mediaLibraryLinks: [{ url: '' }],
            wantsToAddReady:null,
            wantsToAddItemized:null,
        },
        metadata:{
            createdBy: '',
            createdDate: '',
            updatedBy: '',
            updatedDate: ''
            }
    });
    /*
                        END OF PART 1
    */
    /* 
    ||||||||||||||||||||||||||||||||||PART 2|||||||||||||||||||||||||||||||||||||||||
    THIS PART IS FOR HANDLING EACH DATA TYPE AND AFTER IT IS SAVED IN THE COMPONENT IT IS PASSED AS A PROP AND THEN IS SAVED WHERE PART 1 LIES
    */
    const handleSave1 = (key, value) => {
        setBasicCompanyInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
        },
        metadata: {
                ...prevState.metadata,
                updatedBy: userEmail, // Replace with actual user
                updatedDate: new Date().toISOString(),
                createdBy: prevState.metadata.createdBy || userEmail,
                createdDate: prevState.metadata.createdDate || new Date().toISOString(),
            },
        }));
    };
    const handleSave2 = (key, value) => {
        setCompanyProfileStage(prevState => ({
            ...prevState,
            data: {
            ...prevState.data,
            [key]: value,
            },
            metadata: {
            ...prevState.metadata,
            updatedBy: userEmail, // Replace with actual user
            updatedDate: new Date().toISOString(),
            createdBy: prevState.metadata.createdBy || userEmail,
            createdDate: prevState.metadata.createdDate || new Date().toISOString(),
            },
        }));
    };    
    const handleSave3 = (key, value) => {
        let formattedValue = value;
        setServicesAndOperations(prevState => ({
            ...prevState,
            data: {
            ...prevState.data,
            [key]: formattedValue,
            },
            metadata: {
            ...prevState.metadata,
            updatedBy: userEmail,
            updatedDate: new Date().toISOString(),
            createdBy: prevState.metadata.createdBy || userEmail,
            createdDate: prevState.metadata.createdDate || new Date().toISOString(),
            },
        }));
    };    
    const handleSave4 = (key, value) => {
        setContactDetails(prevState => ({
            ...prevState,
            data: {
            ...prevState.data,
            [key]: value,
            },
            metadata: {
            ...prevState.metadata,
            updatedBy: userEmail, 
            updatedDate: new Date().toISOString(),
            createdBy: prevState.metadata.createdBy || userEmail,
            createdDate: prevState.metadata.createdDate || new Date().toISOString(),
            },
        }));
    };
    const handleSave5 = (key, value) => {
        setPackagesAndTariff(prevState => ({
            ...prevState,
            data: {
            ...prevState.data,
            [key]: value,
            },
            metadata: {
            ...prevState.metadata,
            updatedBy: userEmail, 
            updatedDate: new Date().toISOString(),
            createdBy: prevState.metadata.createdBy || userEmail,
            createdDate: prevState.metadata.createdDate || new Date().toISOString(),
            },
        }));
        // console.log(packagesAndTariff);
    };
    /*
                        END OF PART 2
    */  
    /* 
    ||||||||||||||||||||||||||||||||||PART 3|||||||||||||||||||||||||||||||||||||||||
    THIS PART IS FOR VALIDATING EACH PART AT EACH SEPARATE STAGE AND THEN HAVING AN ERROR 
    ARRAY AT THE END WHERE IF NULL WE HAVE NO ERRORS ELSE WE ALREADY SPECIFIED WHERE EACH
    ERROR IS AND WE CAN USE THAT TO CHECK AS WELL
    */
    const validateBasicCompanyInfo = () => {
        const newErrors = {};
        // if (!basicCompanyInfo.data.companyName) newErrors.companyName = "*Company legal name is required";
        if (basicCompanyInfo.data.isFamilyRun === null) newErrors.isFamilyRun = "*Family run business field is required";
        if (!basicCompanyInfo.data.yearOfIncorporation) newErrors.yearOfIncorporation = "*Year is required";
        if (!basicCompanyInfo.data.companyRepresentativeName) newErrors.companyRepresentativeName = "*Company representative name is required";
        if (!basicCompanyInfo.data.phoneCode) newErrors.phoneCode = "*Phone code is required";
        if (!basicCompanyInfo.data.phoneNumber) newErrors.phoneNumber = "*Phone number is required";
        if (!basicCompanyInfo.data.email || !isEmail(basicCompanyInfo.data.email)) newErrors.email = "*Email is required";
        if (!basicCompanyInfo.data.designation) newErrors.designation = "*Designation is required";
          // Validate areCompanyOwnersInvolved
          if (basicCompanyInfo.data.areCompanyOwnersInvolved === null) {
            newErrors.areCompanyOwnersInvolved = "*Please select an option";
        }

         // Validate ownersInvolved if areCompanyOwnersInvolved is true
         if (basicCompanyInfo.data.areCompanyOwnersInvolved && (!basicCompanyInfo.data.ownersInvolved || basicCompanyInfo.data.ownersInvolved.length === 0)) {
            newErrors.ownersInvolved = "*Please select at least one owner";
        }
        
        basicCompanyInfo.data.companyOwners.forEach((entry, index) => {
            if (!entry.name) {
                newErrors[`companyOwners_name_${index}`] = '*Name is required';
            }
            if (!entry.email ||  !isEmail(entry.email)) {
                newErrors[`companyOwners_email_${index}`] = '*Email is required';
            }
        });
    
        if(!basicCompanyInfo.data.companyWebsite || !(isValidUrl(basicCompanyInfo.data.companyWebsite))){
            newErrors.companyWebsite = "*Please enter a valid website url";
        }
        // basicCompanyInfo.data.platforms.forEach((platform, index) => {
        //     const urlCheck = isValidUrl(platform.url);
        //     if(!urlCheck) newErrors[`url_${index}`] = "* Please enter a valid URL/Link";
        //     if (!platform.platform) newErrors[`platform_${index}`] = "* Please select a platform";
        //     if (!platform.url) newErrors[`url_${index}`] = "* Please enter a URL/Link";
        // });
        if(Object.keys(newErrors).length === 0) stagesCompleted[0] = true;
        return newErrors;
    };
    const validateCompanyProfile = () => {
        const newErrors2 = {};
    
        if (!companyProfileStage.data.countriesProvideTour || companyProfileStage.data.countriesProvideTour.length === 0) 
            newErrors2.countriesProvideTour = "*Please select a country";
    
        if (!companyProfileStage.data.countriesOwnOffices || companyProfileStage.data.countriesOwnOffices.length === 0) 
            newErrors2.countriesOwnOffices = "*Please select a country";
    
        if ((!companyProfileStage.data.GCCCountries  || companyProfileStage.data.GCCCountries.length === 0 )&& companyProfileStage.data.handledUAEGCC ) 
            newErrors2.GCCCountries = "*Please select a country";
    
        if (companyProfileStage.data.handledUAEGCC === null) 
            newErrors2.handledUAEGCC = "*Please answer question";
    
        if (companyProfileStage.data.operatesB2B === null) 
            newErrors2.operatesB2B = "*Please answer question";
    
        if (companyProfileStage.data.bookingsPast5Years === null) 
            newErrors2.bookingsPast5Years = "*Please answer question";
    
        if (companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss === null) 
            newErrors2.isMemberOfAnyLocalOrInternatonalTravelIndustryAss = "*Please answer question";
    
        if (!companyProfileStage.data.registrationGuidelines && companyProfileStage.data.operatesB2B) 
            newErrors2.registrationGuidelines = "*Please enter registration guidelines";
    
        if (!companyProfileStage.data.numFullTimeEmployees) 
            newErrors2.numFullTimeEmployees = "*Please select an option";
    
        if (companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss) {
            companyProfileStage.data.travelIndustryAssociations.forEach((association, index) => {
                if (!association.association) 
                    newErrors2[`association_${index}`] = "* Please select an association";
                
                if (!association.membershipStartYear) 
                    newErrors2[`membershipStartYear_${index}`] = "* Please enter a year";
            });
        }
        
        
        companyProfileStage.data.businessLicense.forEach((platform, index) => {
            if (!platform.selectedCountry) 
                newErrors2[`selectedCountry_${index}`] = "*Please select a country";
            
            if (!platform.name && !platform.url) 
                newErrors2[`file_${index}`] = "* Please choose a file";

       
        });
    
    
    
        if (Object.keys(newErrors2).length === 0) 
            stagesCompleted[1] = true;
    
        return newErrors2;
    };
    const validateServicesAndOperations = () => {
        const newErrors2 = {};
    
        if (!servicesAndOperations.data.segmentsCaterTo || servicesAndOperations.data.segmentsCaterTo.length === 0) 
            newErrors2.segmentsCaterTo = "*Please select an option";
        
        if (!servicesAndOperations.data.sourceMarketsIncoming || servicesAndOperations.data.sourceMarketsIncoming.length === 0) 
            newErrors2.sourceMarketsIncoming = "*Please select an option";
    
        if (servicesAndOperations.data.sourceMarketsPercentages.reduce((sum, item) => sum + parseInt(item.percentage), 0) !== 100){            
            newErrors2.sourceMarketsIncoming = "*Please ensure percentages add up to 100";
        }

        if (servicesAndOperations.data.supplyToTravelAgentsUAE === null) 
            newErrors2.supplyToTravelAgentsUAE = "*Please answer question";
    
        if (servicesAndOperations.data.providesSIC === null) 
            newErrors2.providesSIC = "*Please answer question";
    
        if (servicesAndOperations.data.acceptsFIT === null) 
            newErrors2.acceptsFIT = "*Please answer question";
    
        if (servicesAndOperations.data.partnershipsWithSIC === null) 
            newErrors2.partnershipsWithSIC = "*Please answer question";
    
        if (servicesAndOperations.data.maintainOwnVehicleFleet === null) 
            newErrors2.maintainOwnVehicleFleet = "*Please answer question";
    
        if (servicesAndOperations.data.provideVisaRelatedServices === null) 
            newErrors2.provideVisaRelatedServices = "*Please answer question";
    
        if (servicesAndOperations.data.officesOperateOutboundTravel === null) 
            newErrors2.officesOperateOutboundTravel = "*Please answer question";
        
        if (servicesAndOperations.data.acceptsFIT && (servicesAndOperations.data.percentageIsFIT === '' || servicesAndOperations.data.percentageIsFIT === null))
            newErrors2.percentageIsFIT = "*Please enter percentage";
    
        if ((!servicesAndOperations.data.countriesBookSICToursThirdParty || servicesAndOperations.data.countriesBookSICToursThirdParty.length === 0) && servicesAndOperations.data.partnershipsWithSIC) 
            newErrors2.countriesBookSICToursThirdParty = "*Please select a country";

        if ((!servicesAndOperations.data.officesOperatedOutbound || servicesAndOperations.data.officesOperatedOutbound.length === 0) && servicesAndOperations.data.officesOperateOutboundTravel) 
            newErrors2.officesOperatedOutbound = "*Please select a country";
    
        if (servicesAndOperations.data.provideVisaRelatedServices) {
            servicesAndOperations.data.visaCountryAndDescription.forEach((entry, index) => {
                if (!entry.country) {
                    newErrors2[`country_${index}`] = '*Country is required';
                }
                if (!entry.text) {
                    newErrors2[`text_${index}`] = '*Description is required';
                }
            });
        }
    
        if (servicesAndOperations.data.supplyToTravelAgentsUAE) {
            servicesAndOperations.data.UAETravelAgents.forEach((entry, index) => {
                if (!entry.designation) {
                    newErrors2[`designation_${index}`] = '*Company Name is required';
                }
            });
        }
    
        if (!servicesAndOperations.data.tourGuideLanguages || servicesAndOperations.data.tourGuideLanguages.length === 0) 
            newErrors2.tourGuideLanguages = "*Please select a language";
    
        if (!servicesAndOperations.data.vehicleDetails && servicesAndOperations.data.maintainOwnVehicleFleet) 
            newErrors2.vehicleDetails = "*Please enter vehicle details";
    
        if (Object.keys(newErrors2).length === 0) 
            stagesCompleted[2] = true;
    
        return newErrors2;
    }
    const validateContactDetails = () => {
        let newErrors = {};
    

           
        
        contactDetails.data.bankDetails.forEach((row, index) => {

            if (row.selectedCountry.length === 0) {
                newErrors[`selectedCountry_${index}`] = '* Please select at least one country';
            }
            if (!row.name) {
                newErrors[`file_${index}`] = '* Please choose a file';
            }
        });
  
            contactDetails.data.officeSetupDetailsAfterOfficeHours.forEach((row, index) => {
                if (!row.code1) newErrors[`officeSetupDetailsAfterOfficeHours_code1_${index}`] = '*Phone code is required';
                if (!row.code2) newErrors[`officeSetupDetailsAfterOfficeHours_code2_${index}`] = '*Phone code is required';
                if (!row.phoneNumber1) newErrors[`officeSetupDetailsAfterOfficeHours_phoneNumber1_${index}`] = '*Phone number is required';
                if (!row.phoneNumber2) newErrors[`officeSetupDetailsAfterOfficeHours_phoneNumber2_${index}`] = '*Phone number is required';
                // console.log('TEST',row.phoneNumber1);
            });
        
    
      
            contactDetails.data.officeSetupDetailsDuringOfficeHours.forEach((row, index) => {
                if (!row.code1) newErrors[`officeSetupDetailsDuringOfficeHours_code1_${index}`] = '*Phone code is required';
                if (!row.code2) newErrors[`officeSetupDetailsDuringOfficeHours_code2_${index}`] = '*Phone code is required';
                if (!row.phoneNumber1) newErrors[`officeSetupDetailsDuringOfficeHours_phoneNumber1_${index}`] = '*Phone number is required';
                if (!row.phoneNumber2) newErrors[`officeSetupDetailsDuringOfficeHours_phoneNumber2_${index}`] = '*Phone number is required';
            });
        
    
      
            contactDetails.data.contactDetailsQuotes.forEach((row, index) => {
                if (!row.name) newErrors[`contactDetailsQuotes_name_${index}`] = '*Name is required';
                if (!row.code) newErrors[`contactDetailsQuotes_code_${index}`] = '*Phone code is required';
                if (!row.phoneNumber) newErrors[`contactDetailsQuotes_phoneNumber_${index}`] = '*Phone number is required';
                if (!row.email  ||  !isEmail(row.email)) newErrors[`contactDetailsQuotes_email_${index}`] = '*Email is required';
            });
        
    
   
            contactDetails.data.escalationPointsOfContact.forEach((row, index) => {
                if (!row.name) newErrors[`escalationPointsOfContact_name_${index}`] = '*Name is required';
                if (!row.code) newErrors[`escalationPointsOfContact_code_${index}`] = '*Phone code is required';
                if (!row.phoneNumber) newErrors[`escalationPointsOfContact_phoneNumber_${index}`] = '*Phone number is required';
                if (!row.email ||  !isEmail(row.email)) newErrors[`escalationPointsOfContact_email_${index}`] = '*Email is required';
            });
        
    
    
            contactDetails.data.workingDays.forEach((row, index) => {
                if (!row || row.length === 0) newErrors[`workingDays_${index}`] = '*Please select a day';
            });
        
    

            contactDetails.data.financeOrAccountingDetails.forEach((row, index) => {
                if (!row.name) newErrors[`financeOrAccountingDetails_name_${index}`] = '*Name is required';
                if (!row.code) newErrors[`financeOrAccountingDetails_code_${index}`] = '*Phone code is required';
                if (!row.phoneNumber) newErrors[`financeOrAccountingDetails_phoneNumber_${index}`] = '*Phone number is required';
                if (!row.email || !isEmail(row.email)) newErrors[`financeOrAccountingDetails_email_${index}`] = '*Email is required';
            });
        
    
      
    
        // Validate canAcceptPaymentsCreditCard
        if (contactDetails.data.canAcceptPaymentsCreditCard === null) {
            newErrors.canAcceptPaymentsCreditCard = "*Please select an option";
        }

          // Validate isSingle
    if (contactDetails.data.isSingle === null) {
        newErrors.isSingle = "*Please select an option";
    }

    // Add similar validation for other isSingle fields in your form
    if (contactDetails.data.isSingle1 === null) {
        newErrors.isSingle1 = "*Please select an option";
    }

    if (contactDetails.data.isSingle2 === null) {
        newErrors.isSingle2 = "*Please select an option";
    }
    
        // Validate singleContactQuote
        if (contactDetails.data.singleContactQuote === null) {
            newErrors.singleContactQuote = "*Please select an option";
        }
    
       
        // Validate bankDetails
       
    
        // Update stagesCompleted if there are no errors
        if (Object.keys(newErrors).length === 0) {
            stagesCompleted[3] = true;
        }
    
        return newErrors;
    };
    const validatePackagesAndTariffs = () => {
        let newErrors = {};
        if(packagesAndTariff.data.hasReadyMadePackages && packagesAndTariff.data.wantsToAddReady){
        packagesAndTariff.data.readyMadePackages.forEach((row,index) =>
        {
        
            if (!row.fileName && !row.url) {
                newErrors[`ready_file_${index}`] = '*File or URL is required';
            }
            if (row.url && !isValidUrl(row.url)) {
                newErrors[`ready_url_${index}`] = '*Invalid URL';
            }
            if (!row.start) {
                newErrors[`ready_start_${index}`] = '*Start Date is required';
            }
            if (!row.end) {
                newErrors[`ready_end_${index}`] = '*End Date is required';
            }
            if(!row.country)
            {
                newErrors[`ready_country${index}`] = '*Country is required';
            }
        
    });
}
   


if(packagesAndTariff.data.hasReadyMadePackages === null){
    newErrors.hasReadyMadePackages = '*Please select an option';
}

if(packagesAndTariff.data.hasItemizedTariffSheets === null){
    newErrors.hasItemizedTariffSheets= '*Please select an option';
}

if(packagesAndTariff.data.wantsToAddItemized === null){
    newErrors.wantsToAddItemized= '*Please select an option';
}

if(packagesAndTariff.data.wantsToAddReady === null){
    newErrors.wantsToAddReady= '*Please select an option';
}




if(packagesAndTariff.data.hasItemizedTariffSheets && packagesAndTariff.data.wantsToAddItemized){
    packagesAndTariff.data.itemizedTariffSheets.forEach((row,index) =>
        {
            if (!row.service) {
                newErrors[`itemized_service_${index}`] = '*Service is required';
            }
           
            if (!row.fileName && !row.url) {
                newErrors[`itemized_file_${index}`] = '*File or URL is required';
            }
            if (row.url && !isValidUrl(row.url)) {
                newErrors[`itemized_url_${index}`] = '*Invalid URL';
            }
            if (!row.start) {
                newErrors[`itemized_start_${index}`] = '*Start Date is required';
            }
            if (!row.end) {
                newErrors[`itemized_end_${index}`] = '*End Date is required';
            }
            if(!row.country)
                {
                    newErrors[`itemized_country${index}`] = '*Country is required';
                }
    });
}

    // packagesAndTariff.data.mediaLibraryLinks.forEach((platform, index) => {
    //     const urlCheck = isValidUrl(platform.url);
    //     if(!urlCheck) newErrors[`url_${index}`] = "* Please enter a valid URL/Link";
    //     if (!platform.url) newErrors[`url_${index}`] = "* Please enter a URL/Link";
    // });
   
    return newErrors;
    
    };  
    const fetchProfileData = async () => {
        // console.log('Fetching profile data using access token from cookie');
        let email, data;
        try {
           const tokenVerification = await API.get('/DMC/verify-token', {withCredentials: true});
           email = tokenVerification.data.email;
        } catch (error) {
            console.error('Error verifying token:', error);
            navigate("/partner-login-with-email");
            return;
        }
        try {
            const response = await API.get('/DMC/getUserDetails', {
                headers: {
                  'Authorization': `Bearer ${cookies.access_token}`,
                }
            });
            data = await response.data
        } catch (error) {
            navigate("/partner-login-with-email");
            return;
        }
        //   console.log('Fetched profile data:', data);
        const { profile, id } = data;
        setBasicCompanyInfo(profile.basicCompanyInfo);
        setCompanyProfileStage(profile.companyProfileStage);
        setServicesAndOperations(profile.servicesAndOperations);
        setContactDetails(profile.contactDetails);
        setPackagesAndTariff(profile.packagesAndTariff);
        setCurrentStage(profile.currentStage || 1); // Set current stage or default to 1
        handleSave1('id', id);
        setUserEmail(email);
        
    };  
    const handleSubmit = async (moveTo = 0) => {
        console.log(userEmail, "UserEmail");
        let newErrors = {};
        if (currentStage === 1) {
            newErrors = validateBasicCompanyInfo();
        } else if (currentStage === 2) {
            newErrors = validateCompanyProfile();
            // companyProfileStage
        } else if (currentStage === 3) {
            newErrors = validateServicesAndOperations();
            // servicesAndOperations
        } else if (currentStage === 4) {
            newErrors = validateContactDetails();
            // contactDetails
        } else {
            newErrors = validatePackagesAndTariffs();
            // packagesAndTariff
        }
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setShowToast(true);
            if ('scrollTo' in window) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                // Fallback code for browsers that do not support window.scrollTo
                window.scroll(0, 0);
            }            
            return;
        }
    
        setErrors({});
        try {
            const dataToSave = {
                id: basicCompanyInfo.data.id,
                basicCompanyInfo,
                companyProfileStage,
                servicesAndOperations,
                contactDetails,
                packagesAndTariff,
                currentStage
            };    
            // console.log("Data to be saved:", JSON.stringify(dataToSave, null, 2));
            const response = await API.post('/DMC/partnerFormSubmission', dataToSave);
            const data = await response.data;
            // console.log(`Response from server: ${JSON.stringify(data)}`);
            if (response.status === 200) {
                if (!basicCompanyInfo.data.id) {
                    setBasicCompanyInfo(prevState => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            id: data._id,
                        },
                    }));
                }
                // console.log(stagesCompleted);
                setErrors({});
                if (moveTo === 0) {
                    if (currentStage < 5) {
                        setCurrentStage(currentStage + 1);
                    } else {
                        window.location.href = '/review';
                    }
                } else if (moveTo !== 0) {
                    // console.log('moving to......', moveTo);
                    setCurrentStage(moveTo);
                }
            } else {
                console.error('Error saving data:', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    /*
                        END OF PART 4
    */
    /* 
    ||||||||||||||||||||||||||||||||||PART 5|||||||||||||||||||||||||||||||||||||||||

    THIS PART IS FOR RENDERING EACH STAGE AND EACH STAGE HAS THEIR OWN PAGE TO BE 
    RENDERED IN THE MAIN PAGE
    */

    const renderStage1 = () => (
        <>
            <Row className="pb-10 d-none d-md-flex">
                <Col sm={1}></Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage1_black.svg`} backgroundColor={"#0496FF"} tooltipText='Basic Company Information' opacity={0.7} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage2_white.svg`} backgroundColor={(companyProfileStage.metadata.createdBy !=='') ? '#08A4BD' : "#343A40"} isClickable={companyProfileStage.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(2);
                    setShowConfirmationToast(true);
                    }} tooltipText='Company Profile' opacity={0.5} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage3_white.svg`} backgroundColor={(servicesAndOperations.metadata.createdBy !=='') ? '#08A4BD' : "#343A40"} isClickable={servicesAndOperations.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(3);
                    setShowConfirmationToast(true);
                    }} tooltipText='Services and Operations' opacity={0.5} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage4_white.svg`} backgroundColor={(contactDetails.metadata.createdBy!=='') ? '#08A4BD' : "#343A40"} isClickable={contactDetails.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(4);
                    setShowConfirmationToast(true);
                    }} tooltipText='Contact Details' opacity={0.5} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage5_white.svg`} backgroundColor={packagesAndTariff.metadata.createdBy !== '' ? '#08A4BD' : "#343A40"} isClickable={packagesAndTariff.metadata.createdBy !== ''} onClickFunction={() => {
                    setMoveToStage(5);
                    setShowConfirmationToast(true);
                    }} tooltipText='Packages & Tariff Upload' opacity={0.5} />
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="my-5 text-center">
                <Col md={6}>               
                    <p className="description-text">Please provide essential details about your company to help us understand your business better. This information will lay the foundation for a seamless partnership.</p>
                </Col>
            </Row>
            <Row className="my-3">
                <ContactForm
                    selectedPrefix={basicCompanyInfo.data.phoneCode}
                    setSelectedPrefix={(value) => handleSave1('phoneCode', value)}
                    name={basicCompanyInfo.data.companyRepresentativeName}
                    setName={(value) => handleSave1('companyRepresentativeName', value)}
                    phoneNumber={basicCompanyInfo.data.phoneNumber}
                    setPhoneNumber={(value) => handleSave1('phoneNumber', value)}
                    email={basicCompanyInfo.data.email}
                    disable = {true}
                    setEmail={(value) => handleSave1('email', value)}
                    designation={basicCompanyInfo.data.designation}
                    setDesignation={(value) => handleSave1('designation', value)}
                    isError={errors.companyRepresentativeName || errors.phoneCode || errors.phoneNumber || errors.email || errors.designation}
                    supportingText="Please enter name, phone number, email, and designation"
                    errorText="*Please completely fill field"
                />
            </Row>
            <Row className="my-3">
                <Text_input
                    supportingText="Please enter the legal name of your company"
                    inputText="Company legal name"
                    inputValue={basicCompanyInfo.data.companyName}
                    setInputValue={(value) => handleSave1('companyName', value)}
                    isError={errors.companyName}
                    errorText="*Please answer question"
                    placeholder="Enter company name"
                    disabled = {true}
                    
                />
            </Row>
            <Row className="my-3">
                <Text_input
                    supportingText="Enter the year your company was incorporated"
                    inputText="Enter Year of Incorporation"
                    inputValue={basicCompanyInfo.data.yearOfIncorporation}
                    setInputValue={(value) => handleSave1('yearOfIncorporation', value)}
                    isError={errors.yearOfIncorporation}
                    errorText="*Please answer question"
                    placeholder="Enter Year of Incorporation"
                    type = 'Number'
                    maxLength={4}
                />
            </Row>
            <Row className="my-5">
                <CheckBox
                    text="Are you a family run business?"
                    isChecked={basicCompanyInfo.data.isFamilyRun}
                    setIsChecked={(value) => handleSave1('isFamilyRun', value)}
                    isError={errors.isFamilyRun}
                    errorText="*Please answer question :)"
                />
            </Row>
            <Row className = 'my-4'>
                <ContactDetails 
                initialData={basicCompanyInfo.data.companyOwners} 
                setRowsData={(value) => handleSave1('companyOwners',value)} 
                supportingText={"Provide the details of the company owners."} 
                label = {'Company Owners'} 
                withAdd = {true}
                errors = {errors}
                />
            </Row>

            <Row className='my-4'>
            <CheckBox
                    text="Are the company owners actively involved in the day-to-day running of the business?"
                    isChecked={basicCompanyInfo.data.areCompanyOwnersInvolved}
                    setIsChecked={(value) => handleSave1('areCompanyOwnersInvolved', value)}
                    isError={errors.areCompanyOwnersInvolved}
                    errorText={errors.areCompanyOwnersInvolved}
                />
            </Row>
            {basicCompanyInfo.data.areCompanyOwnersInvolved && (
                <Row className='my-4'>
                <MultiSelectDropdown
                        inputText="Select the owners actively involved in the day-to-day running of the business"
                        description="Select Owners"
                        selectedCountries={basicCompanyInfo.data.ownersInvolved}
                        setSelectedCountries={(value) => handleSave1('ownersInvolved', value)}
                        options={basicCompanyInfo.data.companyOwners.map(owner => ({ value: owner.name, label: owner.name }))}
                        isError={errors.ownersInvolved}
                        errorText={errors.ownersInvolved}
                        placeholder='Select Owners'
                    />
                </Row>
            )}
            <Row className="my-3">
                <Text_input
                    supportingText="Enter your company website"
                    inputText="Company Website"
                    inputValue={basicCompanyInfo.data.companyWebsite}
                    setInputValue={(value) => handleSave1('companyWebsite', value)}
                    isError={errors.companyWebsite}
                    errorText="*Please enter a valid URL"
                    placeholder="example.com"
                    type = 'text'
                />
            </Row>
            <Row className="my-3">
                <Handles
                    rows={basicCompanyInfo.data.platforms}
                    setRows={(rows) => handleSave1('platforms', rows)}
                    errors={errors}
                    validateOnSave={handleSubmit}
                />
            </Row>
            <Row className="my-4">
                <Col md={4}></Col>
                <Col md={8} className='align-items-center mt-3'>
                    <BootstrapButton  variant="primary" onClick={() => handleSubmit()}>Save and Continue</BootstrapButton>
                </Col>
            </Row>
        </>
    );

    const gccCountries = [
        { value: 'Bahrain', label: 'Bahrain' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Qatar', label: 'Qatar' },
        { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates' }
    ];
    const options = [
        { value: '1-10', label: '1-10' },
        { value: '11-20', label: '11-20' },
        { value: '21-30', label: '21-30' },
        { value: '31-50', label: '31-50' },
        { value: '51+', label: '51+' }
    ];
    const renderStage2 = () => (
        <>
            <Row className="pb-10 d-none d-md-flex">
                <Col sm={1}></Col>
                <Col sm={2}>
                <Ellipse 
                    imageSrc={`${process.env.PUBLIC_URL}/images/stage1_black.svg`} 
                    backgroundColor={"#08A4BD"} 
                    isClickable={true} 
                    onClickFunction={() => {
                    setMoveToStage(1);
                    setShowConfirmationToast(true);
                    }} 
                    tooltipText='Basic Company Information' 
                    opacity={1} 
                />
                </Col>
                <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage2_black.svg`} backgroundColor={"#0496FF"} tooltipText='Company Profile' opacity={0.7} />
                </Col>
                <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage3_white.svg`} backgroundColor={(servicesAndOperations.metadata.createdBy !=='') ? '#08A4BD' : "#343A40"} isClickable={servicesAndOperations.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(3);
                    setShowConfirmationToast(true);
                }} tooltipText='Services and Operations' opacity={0.5} />
                </Col>
                <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage4_white.svg`} backgroundColor={(contactDetails.metadata.createdBy !=='') ? '#08A4BD' : "#343A40"} isClickable={contactDetails.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(4);
                    setShowConfirmationToast(true);
                }} tooltipText='Contact Details' opacity={0.5} />
                </Col>
                <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage5_white.svg`} backgroundColor={packagesAndTariff.metadata.createdBy !== '' ? '#08A4BD' : "#343A40"} isClickable={packagesAndTariff.metadata.createdBy !== ''} onClickFunction={() => {
                    setMoveToStage(5);
                    setShowConfirmationToast(true);
                }} tooltipText='Packages & Tariff Upload' opacity={0.5} />
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="my-5 text-center">
                <Col md={6}>
                    <p className="description-text">Provide detailed information about your operational capabilities and memberships. This will help us understand the extent of your services and affiliations.</p>
                </Col>
            </Row>            
            <Row className="my-3">
            <CheckBox
                    text="Are you a member of any local or international travel industry trade association?"
                    isChecked={companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss}
                    setIsChecked={(value) => handleSave2('isMemberOfAnyLocalOrInternatonalTravelIndustryAss', value)}
                    isError={errors.isMemberOfAnyLocalOrInternatonalTravelIndustryAss}
                    errorText={errors.isMemberOfAnyLocalOrInternatonalTravelIndustryAss}
                />
            </Row>
            {companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss && (
                <Row className="my-4">
                    <TwoText
                            inputText="Please mention travel industry trade associations"
                            placeholder1="Enter Travel/Trade Associations"
                            placeholder2="Year"                                                                                                       
                            description="Enter the trade associations and the years joined."
                            rowsData={companyProfileStage.data.travelIndustryAssociations}
                            setRowsData={(rows) => handleSave2('travelIndustryAssociations', rows)}
                            errors={errors}
                            validateOnSave={handleSubmit}    />
                </Row>
            )}
            <Row className="my-4">
                 <CountriesWithCode
                    showCountrySelector={true}
                    inputText="Attach your business license"
                    description="Upload a copy of your business license that shows you are legally authorized to carry out business as a DMC."
                    showControls={true}
                    rowsData={companyProfileStage.data.businessLicense}
                    setRowsData={(rows) => handleSave2('businessLicense', rows)}
                    companyName={`${basicCompanyInfo.data.companyName}/Business License(s)`}
                    companyNameGet = {basicCompanyInfo.data.companyName}
                    errors={errors}
                    directory={'Business License(s)'}
                />
            </Row>
            <Row className="my-4">
                <FileChooser
                    label="Attach your company profile"
                    description="Upload a copy of your company profile."
                    fileData={companyProfileStage.data.companyInfo}
                    setFileData={(rows) => handleSave2('companyInfo', rows)}
                    isError = {errors.companyInfo}
                    errorText={errors.companyInfo}
                    companyName={`${basicCompanyInfo.data.companyName}/CompanyInfo`}
                    companyNameGet = {basicCompanyInfo.data.companyName}
                    directory={'CompanyInfo'}
                />
            </Row>
            <Row className="my-4">
                <MultiSelectDropdown
                    inputText="Select the countries where you provide tour operation services"
                    description="Select the countries where your company provides tours."
                    selectedCountries={companyProfileStage.data.countriesProvideTour}
                    setSelectedCountries={(countries) => handleSave2('countriesProvideTour', countries)}
                    options={countries.map(country => ({ value: country.label, label: country.label }))}
                    isError={errors.countriesProvideTour}
                    errorText={errors.countriesProvideTour}
                    doList = {true}
                />
            </Row>
            <Row className='my-4'>
                <MultiSelectDropdown
                    inputText="Select the countries where you have your own offices"
                    description="Select the countries where you have fully owned and operated offices, and where you employ full time staff."
                    selectedCountries={companyProfileStage.data.countriesOwnOffices}
                    setSelectedCountries={(countries) => handleSave2('countriesOwnOffices', countries)}
                    options={countries.map(country => ({ value: country.label, label: country.label }))}
                    isError={errors.countriesOwnOffices}
                    errorText={errors.countriesOwnOffices}
                    doList = {true}
                />
            </Row>
            {companyProfileStage?.data?.countriesOwnOffices && companyProfileStage.data.countriesOwnOffices.length > 0 && (
                <Row className="my-4">
                    <OfficeAddressInput
                        selectedCountries={companyProfileStage.data.countriesOwnOffices}
                        setSelectedCountries={(countries) => handleSave2('countriesOwnOffices', countries)}
                    />
                </Row>
            )}
            <Row className='my-4'> 
                <SingleSelect
                    inputText="Select the total number of employees"
                    selectedValue={companyProfileStage.data.numFullTimeEmployees}
                    setSelectedValue={(value) => handleSave2('numFullTimeEmployees', value)}
                    description="Please select the range that best describes the number of full-time employees in your company."
                    isError={errors.numFullTimeEmployees}
                    errorText={errors.numFullTimeEmployees}
                    placeholder={"Select range of employees"}
                    options = {options}
                />
            </Row>
            <Row className='my-4'>
                <CheckBox
                    text="Have you handled clients who are residents of the United Arab Emirates, or any other GCC Country?"
                    isChecked={companyProfileStage.data.handledUAEGCC}
                    setIsChecked={(value) => handleSave2('handledUAEGCC', value)}
                    isError={errors.handledUAEGCC}
                    errorText={errors.handledUAEGCC}
                />
            </Row>
            {companyProfileStage.data.handledUAEGCC && (
                <Row className='my-4'>
                    <Col md={4}></Col>
                    <MultiSelectDropdown
                        inputText=""
                        description="Indicate if you have handled clients from the UAE or other GCC countries."
                        selectedCountries={companyProfileStage.data.GCCCountries}
                        setSelectedCountries={(countries) => handleSave2('GCCCountries', countries)}
                        options={gccCountries}
                        isError={errors.GCCCountries}
                        errorText={errors.GCCCountries}
                    />
                </Row>
            )}
            <Row className="my-4">
                <CheckBox
                    text="Does your company operate a B2B online booking system for our access?"
                    isChecked={companyProfileStage.data.operatesB2B}
                    setIsChecked={(value) => handleSave2('operatesB2B', value)}
                    isError={errors.operatesB2B}
                    errorText={errors.operatesB2B}
                />
            </Row>
            {companyProfileStage.data.operatesB2B && (
                <Row className="my-4">
                    <Text_input 
                        inputText="Please provide registration guidelines for B2B system"
                        supportingText=" "
                        inputValue={companyProfileStage.data.registrationGuidelines}
                        setInputValue={(value) => handleSave2('registrationGuidelines', value)}
                        placeholder="Enter registration guidelines or provide a link "
                        isError={errors.registrationGuidelines}
                        errorText={errors.registrationGuidelines}
                        isTextarea = {true}
                    />
                </Row>
            )}
            <Row className="my-4">
                <CheckBox
                    text="Have you received bookings from us in the past 5 years?"
                    isChecked={companyProfileStage.data.bookingsPast5Years}
                    setIsChecked={(value) => handleSave2('bookingsPast5Years', value)}
                    isError={errors.bookingsPast5Years}
                    errorText={errors.bookingsPast5Years}
                />
            </Row>
            <Row className="my-4">
                <Col md={4}></Col>
                <Col md={8}>
                    <BootstrapButton className = 'm-4' variant="secondary" onClick={() => setCurrentStage(1)}>Back</BootstrapButton>
                    <BootstrapButton variant="primary" onClick={() => handleSubmit()}>Save and Continue</BootstrapButton>
                </Col>
            </Row>
                 
        </>
    );
    const catering = [
        'Adventure Tourism', 'Sports Tourism','Ski Holidays', 'Golf Tourism', 
        'Ocean Cruises', 'River Cruises',
        'Guaranteed Departure Daily Tours', 'Escorted Multi-day Group Tours', 'Private Group Trips', 'Corporate Incentives',
        'University Groups', 'High School Groups', 
        'Honeymoons', 
        'Ladies only tours', 'Accessible Tourism','Medical Tourism', 
        'Halal Tourism', 'Religious & Pilgrimage Tourism'
    ];
    const renderStage3 = () => (
        <>
           <Row className="pb-10 d-none d-md-flex">
                <Col sm={1}></Col>
                <Col sm={2}>
                    <Ellipse 
                    imageSrc={`${process.env.PUBLIC_URL}/images/stage1_black.svg`} 
                    backgroundColor={"#08A4BD"} 
                    isClickable={true} 
                    onClickFunction={() => {
                        setMoveToStage(1);
                        setShowConfirmationToast(true);
                    }} 
                    tooltipText='Basic Company Information' 
                    opacity={1} 
                    />
                </Col>
                <Col sm={2}>
                    <Ellipse 
                    imageSrc={`${process.env.PUBLIC_URL}/images/stage2_black.svg`} 
                    backgroundColor={"#08A4BD"} 
                    isClickable={true} 
                    onClickFunction={() => {
                        setMoveToStage(2);
                        setShowConfirmationToast(true);
                    }} 
                    tooltipText='Company Profile' 
                    opacity={1} 
                    />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage3_black.svg`} backgroundColor={"#0496FF"} tooltipText='Services and Operations' opacity={0.7} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage4_white.svg`} backgroundColor={(contactDetails.metadata.createdBy !=='') ? '#08A4BD' : "#343A40"} isClickable={contactDetails.metadata.createdBy !==''} onClickFunction={() => {
                    setMoveToStage(4);
                    setShowConfirmationToast(true);
                    }} tooltipText='Contact Details' opacity={0.5} />
                </Col>
                <Col sm={2}>
                    <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage5_white.svg`} backgroundColor={packagesAndTariff.metadata.createdBy !== '' ? '#08A4BD' : "#343A40"} isClickable={packagesAndTariff.metadata.createdBy !== ''} onClickFunction={() => {
                    setMoveToStage(5);
                    setShowConfirmationToast(true);
                    }} tooltipText='Packages & Tariff Upload' opacity={0.5} />
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="my-5 text-center">
                <Col md={6}>
                    <p className="description-text">Provide detailed information about the services you offer and your operational capabilities.</p>
                </Col>
            </Row>
            <Row className='my-4'>
            <Options
                label="Which of the below segments do you cater to?"
                options={catering}
                selectedOptions={servicesAndOperations.data.segmentsCaterTo}
                setSelectedOptions={(value) => handleSave3('segmentsCaterTo', value)}
                isError={errors.segmentsCaterTo}
                errorText="*Please select at least one option"
            />
            </Row>
            <Row className='my-4'>
            <MultiSelectDropdown
                    inputText="What are your Top 5 source markets for incoming?"
                    description="Select up to 5 source markets for inbound tourism."
                    selectedCountries={servicesAndOperations.data.sourceMarketsIncoming}
                    setSelectedCountries={(countries) => handleSave3('sourceMarketsIncoming', countries)}
                    options={countries.map(country => ({ value: country.label, label: country.label }))}
                    isError={errors.sourceMarketsIncoming}
                    errorText={errors.sourceMarketsIncoming}
                    maxSelectLimit={5}
                    enableAdditionalFunctionality = {true}
                    rows = {servicesAndOperations.data.sourceMarketsPercentages}
                    setRows = {(countries) => handleSave3('sourceMarketsPercentages', countries)}
                />
            </Row>
            <Row className='my-4'>
            <CheckBox
                    text="Do you currently supply services to travel agents in the UAE?"
                    isChecked={servicesAndOperations.data.supplyToTravelAgentsUAE}
                    setIsChecked={(value) => handleSave3('supplyToTravelAgentsUAE', value)}
                    isError={errors.supplyToTravelAgentsUAE}
                    errorText={errors.supplyToTravelAgentsUAE}
                />
            </Row>
            <Row className = 'my-4'>
            {/* const ContactFormWithAdd = ({ initialData, setRowsData, isError, errorText, supportingText, placeholder1 = 'Enter Company Name', placeholder2 = 'Enter Agent Name' }) => { */}
            {servicesAndOperations.data.supplyToTravelAgentsUAE && (
                <ContactFormWithAdd
                    withAdd={true}
                    initialData={servicesAndOperations.data.UAETravelAgents}
                    supportingText="Please fill out all required fields."
                    setRowsData ={(value) => handleSave3('UAETravelAgents', value)
                    }
                    label = 'UAE Travel Agents'
                    errors = {errors}
                />
            )}
            </Row>
            <Row className='my-4'>
                 <CheckBox
                 text="Does your company operate its own seat-in-coach(SIC) tours?"
                 isChecked={servicesAndOperations.data.providesSIC}
                 setIsChecked={(value) => handleSave3('providesSIC', value)}
                 isError={errors.providesSIC}
                 errorText={errors.providesSIC}
                 description=""
             />
            </Row>
            <Row className='my-4'>
                <CheckBox
                    text="Do you have partnerships with companies that offer SIC Tours? "
                    isChecked={servicesAndOperations.data.partnershipsWithSIC}
                    setIsChecked={(value) => handleSave3('partnershipsWithSIC', value)}
                    isError={errors.partnershipsWithSIC}
                    errorText={errors.partnershipsWithSIC}
                    description="Indicate if you have partnerships for booking clients on SIC Tours."
                />
                
            </Row>
            {(servicesAndOperations.data.partnershipsWithSIC) && ( 
                <Row className='my-4'>                        
                    <MultiSelectDropdown
                        inputText="Please select the countries where you can book SIC tours for FIT guests through 3rd party vendors"
                        description="Select countries"
                        selectedCountries={servicesAndOperations.data.countriesBookSICToursThirdParty}
                        setSelectedCountries={(countries) => handleSave3('countriesBookSICToursThirdParty', countries)}
                        options={countries.map(country => ({ value: country.label, label: country.label }))}
                        isError={errors.countriesBookSICToursThirdParty}
                        errorText={errors.countriesBookSICToursThirdParty}
                    />
                </Row>  
            )}
            <Row className='my-4'>
            <CheckBox
                 text="Do you accept FIT bookings?"
                 isChecked={servicesAndOperations.data.acceptsFIT}
                 setIsChecked={(value) => handleSave3('acceptsFIT', value)}
                 isError={errors.acceptsFIT}
                 errorText={errors.acceptsFIT}
                 description="Indicate if you accept FIT (Fully Independent Traveler) business."
             />
            </Row>
            {servicesAndOperations.data.acceptsFIT && ( 
                <Row className='my-4'>
                    <Range
                        label="What % of your annual business is FIT?"
                        description="Specify the percentage of your business that is FIT versus groups."
                        initialValue={servicesAndOperations.data.percentageIsFIT}
                        setPercentage={(value) => handleSave3('percentageIsFIT', value)}
                        isError={errors.percentageIsFIT}
                        errorText="* Please provide a valid percentage"
                    /> 
            </Row>)}
            {/* <MultiSelectDropdown
                inputText="Please select the offices that sell outbound"
                description="Select countries"
                selectedCountries={servicesAndOperations.data.officesOperatedOutbound}
                setSelectedCountries={(countries) => handleSave3('officesOperatedOutbound', countries)}
                options={companyProfileStage.data.countriesOwnOffices.map(country => ({ label: country.label, value: country.label }))}
                isError={errors.officesOperatedOutbound}
                errorText={errors.officesOperatedOutbound}
            /> */}
            <Row className="my-4">
            <MultiSelectDropdown
                inputText="Tour Guide Languages:"
                description="Select Languages"
                selectedCountries={servicesAndOperations.data.tourGuideLanguages}
                setSelectedCountries={(countries) => handleSave3('tourGuideLanguages', countries)}
                options={languages.map(languages => ({ value: languages.label, label: languages.label }))}
                isError={errors.tourGuideLanguages}
                errorText={errors.tourGuideLanguages}
                placeholder = {"Select Languages"}
            />
            </Row>
            <Row className="my-4">
                <CheckBox
                    text="Do you own & maintain your own vehicle fleet? "
                    isChecked={servicesAndOperations.data.maintainOwnVehicleFleet}
                    setIsChecked={(value) => handleSave3('maintainOwnVehicleFleet', value)}
                    isError={errors.maintainOwnVehicleFleet}
                    errorText={errors.maintainOwnVehicleFleet}
                />
            </Row>
            {servicesAndOperations.data.maintainOwnVehicleFleet && (
                <Row className="my-4">
                    <Text_input 
                        inputText=""
                        supportingText="Specify if you own a vehicle fleet and provide details of self-owned vehicles."
                        inputValue={servicesAndOperations.data.vehicleDetails}
                        setInputValue={(value) => handleSave3('vehicleDetails', value)}
                        placeholder="Enter fleet details"
                        isError={errors.vehicleDetails}
                        errorText={errors.vehicleDetails}
                        isTextarea = {true}
                        
                    />
                </Row>  
            )}
             {servicesAndOperations.data.maintainOwnVehicleFleet!== null && !servicesAndOperations.data.maintainOwnVehicleFleet && (
                <Row className="my-4">
                    <Text_input 
                        inputText=""
                        supportingText="Specify if you own a vehicle fleet and provide details of self-owned vehicles."
                        inputValue={servicesAndOperations.data.vehicleDetails}
                        setInputValue={(value) => handleSave3('vehicleDetails', value)}
                        placeholder='Indicate the vehicle types you offer for different group sizes'
                        isError={errors.vehicleDetails}
                        errorText={errors.vehicleDetails}
                        isTextarea = {true}
                        doPlaceHolder = {false}
                        
                    />
                </Row>  
            )}
            <Row className="my-4">
            <CheckBox
                    text="Do you provide visa-related services?"
                    isChecked={servicesAndOperations.data.provideVisaRelatedServices}
                    setIsChecked={(value) => handleSave3('provideVisaRelatedServices', value)}
                    isError={errors.provideVisaRelatedServices}
                    errorText={errors.provideVisaRelatedServices}
                />
            </Row>
            {servicesAndOperations.data.provideVisaRelatedServices && (
                <Row className='my-4'>            
                    <CountryTextInput
                        label=""
                        countryPlaceholder="Select country"
                        textPlaceholder="Explain visa services"
                        description="Describe the visa-related services you offer."
                        addLabel=" Add"
                        countries={countries.map(country => ({ value: country.label, label: country.label }))}
                        errors={errors}
                        setRowsData={(value) => handleSave3('visaCountryAndDescription', value)}
                        rowsData = {servicesAndOperations.data.visaCountryAndDescription}
                    />

                </Row>
            )}
            <Row className="my-4">
                <CheckBox
                    text="Do any of your offices operate as an outbound travel agency? "
                    isChecked={servicesAndOperations.data.officesOperateOutboundTravel}
                    setIsChecked={(value) => handleSave3('officesOperateOutboundTravel', value)}
                    isError={errors.officesOperateOutboundTravel}
                    errorText={errors.officesOperateOutboundTravel}
                />
            </Row>
            {(servicesAndOperations.data.officesOperateOutboundTravel) && (
                <Row className='my-4'>
                    <MultiSelectDropdown
                        inputText="Please select the offices that sell outbound"
                        description="Select countries"
                        selectedCountries={servicesAndOperations.data.officesOperatedOutbound}
                        setSelectedCountries={(countries) => handleSave3('officesOperatedOutbound', countries)}
                        options={companyProfileStage.data.countriesOwnOffices.map(country => ({ label: country.label, value: country.label }))}
                        isError={errors.officesOperatedOutbound}
                        errorText={errors.officesOperatedOutbound}
                    />
                </Row>  
            )}
            {/* tourGuideLanguaes: [],
            maintainOwnVehicleFleet: false,
            vehicleDetails: '' */}
            <Row className="my-4">
                <Col md={4}></Col>
                <Col md={8}>
                    <BootstrapButton className='m-4' variant="secondary" onClick={() => setCurrentStage(2)}>Back</BootstrapButton>
                    <BootstrapButton variant="primary" onClick={() => handleSubmit()}>Save and Continue</BootstrapButton>

                </Col>
            </Row>     
        </>
    );
    // this is for the bank details combining the details of both countries where they provide tours and countries where they own offices
    const countriesProvideTour = companyProfileStage.data.countriesProvideTour.map(country => country.label);
    const countriesOwnOffices = companyProfileStage.data.countriesOwnOffices.map(country => country.label);
    // Combine both arrays and remove duplicates using a Set
    const combinedCountries = [...new Set([...countriesProvideTour, ...countriesOwnOffices])];
    // Map the combined array to the required format
    const combinedOptions = combinedCountries.map(country => ({ value: country, label: country }));
    const renderStage4 = () => (
        <>
            <Row className="pb-10 d-none d-md-flex">
            <Col sm={1}></Col>
            <Col sm={2}>
                <Ellipse 
                imageSrc={`${process.env.PUBLIC_URL}/images/stage1_black.svg`} 
                backgroundColor={"#08A4BD"} 
                isClickable={true} 
                onClickFunction={() => {
                    setMoveToStage(1);
                    setShowConfirmationToast(true);
                }} 
                tooltipText='Basic Company Information' 
                opacity={1} 
                />
            </Col>
            <Col sm={2}>
                <Ellipse 
                imageSrc={`${process.env.PUBLIC_URL}/images/stage2_black.svg`} 
                backgroundColor={"#08A4BD"} 
                isClickable={true} 
                onClickFunction={() => {
                    setMoveToStage(2);
                    setShowConfirmationToast(true);
                }} 
                tooltipText='Company Profile' 
                opacity={1} 
                />
            </Col>
            <Col sm={2}>
                <Ellipse 
                imageSrc={`${process.env.PUBLIC_URL}/images/stage3_black.svg`} 
                backgroundColor={"#08A4BD"} 
                isClickable={true} 
                onClickFunction={() => {
                    setMoveToStage(3);
                    setShowConfirmationToast(true);
                }} 
                tooltipText='Services and Operations' 
                opacity={1} 
                />
            </Col>
            <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage4_black.svg`} backgroundColor={"#0496FF"} tooltipText='Contact Details' opacity={0.7} />
            </Col>
            <Col sm={2}>
                <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage5_white.svg`} backgroundColor={packagesAndTariff.metadata.createdBy !== '' ? '#08A4BD' : "#343A40"} isClickable={packagesAndTariff.metadata.createdBy !== ''} onClickFunction={() => {
                setMoveToStage(5);
                setShowConfirmationToast(true);
                }} tooltipText='Packages & Tariff Upload' opacity={0.5} />
            </Col>
            <Col sm={1}></Col>
            </Row>
            <Row className="my-5 text-center">
                <Col md={6}>
                    <p className="description-text">Provide detailed contact information to ensure we can reach you for any queries or emergencies.</p>
                </Col>
            </Row>  
            <Row className='my-4'>
                <CountryContactDetails
                    supportingText="Provide email addresses for sending quote requests."
                    label="Contact Details of whom we should reach out to for quotes"
                    initialData={contactDetails.data.contactDetailsQuotes}
                    setRowsData={(value) => handleSave4('contactDetailsQuotes', value)}
                    errors={errors}
                    countryList={companyProfileStage.data.countriesProvideTour.map(country => country.label)}
                    checkLabel={"Do you have a single contact point for enquiries across all your destinations?"}
                    name = 'contactDetailsQuotes'
                    isSingle = {contactDetails.data.isSingle}
                    setIsSingle={(value) => handleSave4('isSingle', value)}
                />
            </Row>
            <Row className='my-4'>
                <CountryContactDetails
                    supportingText="Provide the contact details for the escalation point of contact."
                    label="Escalation Point of Contact (Supervisor/Team Leader)"
                    initialData={contactDetails.data.escalationPointsOfContact}
                    setRowsData={(value) => handleSave4('escalationPointsOfContact', value)}
                    errors={errors}
                    countryList={companyProfileStage.data.countriesProvideTour.map(country => country.label)}
                    checkLabel={"Do you have a single escalation point of contact (Supervisor/Team Leader) across destinations?"}
                    name ='escalationPointsOfContact'
                    isSingle = {contactDetails.data.isSingle1}
                    setIsSingle={(value) => handleSave4('isSingle1', value)}
                
                />
            </Row>
            <Row className='my-4'>
                <CountryContactDetails
                    supportingText="Provide the contact details for accounts section."
                    label="Finance/Accounting"
                    initialData={contactDetails.data.financeOrAccountingDetails}
                    setRowsData={(value) => handleSave4('financeOrAccountingDetails', value)}
                    errors={errors}
                    countryList={companyProfileStage.data.countriesProvideTour.map(country => country.label)}
                    checkLabel={"Do you have a single point of contact across destinations for Finance/Accounting?"}
                    name = 'financeOrAccountingDetails'
                    isSingle = {contactDetails.data.isSingle2}
                    setIsSingle={(value) => handleSave4('isSingle2', value)}
                />
            </Row>
            <Row className = 'my-4'>
                <OwnOfficeSetupDetails
                    initialData1={contactDetails.data.officeSetupDetailsDuringOfficeHours}
                    initialData2={contactDetails.data.officeSetupDetailsAfterOfficeHours}
                    setRowsData1={(value) => handleSave4('officeSetupDetailsDuringOfficeHours', value)}
                    setRowsData2={(value) => handleSave4('officeSetupDetailsAfterOfficeHours', value)}
                    errors={errors}
                    supportingText=""
                    countryList={companyProfileStage.data.countriesOwnOffices.map(country => country.label)}
                    name1 = 'officeSetupDetailsDuringOfficeHours'
                    name2 = 'officeSetupDetailsAfterOfficeHours'
                    sameContact={contactDetails.data.sameContact}
                    setSameContact={(value) => handleSave4('sameContact', value)}
                />
            </Row>
            <Row>
                <DaysOptions
                    label="Please select your working days"
                    countryList={companyProfileStage.data.countriesOwnOffices.map(country => country.label)}
                    initialData={contactDetails.data.workingDays}
                    setRowsData={(value) => handleSave4('workingDays', value)}
                    isError={!!errors.workingDays}
                    errorText={errors.workingDays}
                />
            </Row>
            {/* const MultiWithFile = ({ inputText, description, showControls, rowsData, setRowsData, errors = {}, multiSelectOptions, companyName }) => { */}
            <Row className="my-4">
                <MultiWithFile    
                    inputText="Bank Details"
                    description="Provide bank details for each destination/office."
                    showControls={true}
                    rowsData={contactDetails.data.bankDetails}
                    setRowsData={(value) => handleSave4('bankDetails', value)}
                    errors={errors}
                    multiSelectOptions={combinedOptions}
                    //  multiSelectOptions={countries.map(country => ({ value: country.label, label: country.label }))}
                    companyName={`${basicCompanyInfo.data.companyName}/BankDetails`}
                    directory={'BankDetails'}
                    companyNameGet = {basicCompanyInfo.data.companyName}
                />
            </Row>
            <Row className = 'my-4'>
            <CheckBox
                    text="Can you accept payments for bookings by a secure credit card payment link?"
                    isChecked={contactDetails.data.canAcceptPaymentsCreditCard}
                    setIsChecked={(value) => handleSave4('canAcceptPaymentsCreditCard', value)}
                    isError={errors.canAcceptPaymentsCreditCard}
                    errorText={errors.canAcceptPaymentsCreditCard}
                />
            </Row>
            <Row className="my-4">
                <Col md={4}></Col>
                <Col md={8}>
                    <BootstrapButton className='m-4' variant="secondary" onClick={() => setCurrentStage(3)}>Back</BootstrapButton>
                    <BootstrapButton variant="primary" onClick={() => handleSubmit()}>Save and Continue</BootstrapButton>

                </Col>
            </Row>
                 
        </>
    );
    const renderStage5 = () => (
            <>
                <Row className="pb-10 d-none d-md-flex">
                    <Col sm={1}></Col>
                    <Col sm={2}>
                        <Ellipse 
                        imageSrc={`${process.env.PUBLIC_URL}/images/stage1_black.svg`} 
                        backgroundColor={"#08A4BD"} 
                        isClickable={true} 
                        onClickFunction={() => {
                            setMoveToStage(1);
                            setShowConfirmationToast(true);
                        }} 
                        tooltipText='Basic Company Information' 
                        opacity={1} 
                        />
                    </Col>
                    <Col sm={2}>
                        <Ellipse 
                        imageSrc={`${process.env.PUBLIC_URL}/images/stage2_black.svg`} 
                        backgroundColor={"#08A4BD"} 
                        isClickable={true} 
                        onClickFunction={() => {
                            setMoveToStage(2);
                            setShowConfirmationToast(true);
                        }} 
                        tooltipText='Company Profile' 
                        opacity={1} 
                        />
                    </Col>
                    <Col sm={2}>
                        <Ellipse 
                        imageSrc={`${process.env.PUBLIC_URL}/images/stage3_black.svg`} 
                        backgroundColor={"#08A4BD"} 
                        isClickable={true} 
                        onClickFunction={() => {
                            setMoveToStage(3);
                            setShowConfirmationToast(true);
                        }} 
                        tooltipText='Services and Operations' 
                        opacity={1} 
                        />
                    </Col>
                    <Col sm={2}>
                        <Ellipse 
                        imageSrc={`${process.env.PUBLIC_URL}/images/stage4_black.svg`} 
                        backgroundColor={"#08A4BD"} 
                        isClickable={true} 
                        onClickFunction={() => {
                            setMoveToStage(4);
                            setShowConfirmationToast(true);
                        }} 
                        tooltipText='Contact Details' 
                        opacity={1} 
                        />
                    </Col>
                    <Col sm={2}>
                        <Ellipse imageSrc={`${process.env.PUBLIC_URL}/images/stage5_black.svg`} backgroundColor={"#0496FF"} tooltipText='Packages & Tariff Upload' opacity={0.7} />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="my-5 text-center">
                    <Col md={6}>
                        <p className="description-text">Provide detailed contact information to ensure we can reach you for any queries or emergencies.</p>
                    </Col>
                </Row> 
                <Row>
                    <FileOrUrl 
                        label = "Does your company have readymade packages? "
                        initialData={packagesAndTariff.data.readyMadePackages}
                        setRowsData={(value) => handleSave5('readyMadePackages', value)}
                        errors = {errors}
                        selectService = {false}
                        companyName={`${basicCompanyInfo.data.companyName}/ReadyMadePackages`}
                        name = 'ready'
                        yesNo = {packagesAndTariff.data.hasReadyMadePackages}
                        setYesNo= {(value) => handleSave5('hasReadyMadePackages', value)}
                        countries = {countries.map(country => ({ value: country.label, label: country.label }))}
                        directory={'ReadyMadePackages'}
                        companyNameGet = {basicCompanyInfo.data.companyName}
                        wantsTo={packagesAndTariff.data.wantsToAddReady}
                        setWantsTo= {(value) => handleSave5('wantsToAddReady', value)}
                        question = {'Would you like to upload your packages here ? (Optional)'}
                        />
                </Row>
                <Row>
                    <FileOrUrl 
                        label = "Does your company have itemized tariff sheets for hotels, or transfers, or tours? "
                        initialData={packagesAndTariff.data.itemizedTariffSheets}
                        setRowsData={(value) => handleSave5('itemizedTariffSheets', value)}
                        errors = {errors}
                        selectService = {true}
                        companyName={`${basicCompanyInfo.data.companyName}/ItemizedTariffSheets`}
                        name='itemized'
                        yesNo = {packagesAndTariff.data.hasItemizedTariffSheets}
                        setYesNo= {(value) => handleSave5('hasItemizedTariffSheets', value)}
                        countries = {countries.map(country => ({ value: country.label, label: country.label }))}
                        directory={'ItemizedTariffSheets'}
                        companyNameGet = {basicCompanyInfo.data.companyName}
                        wantsTo={packagesAndTariff.data.wantsToAddItemized}
                        setWantsTo= {(value) => handleSave5('wantsToAddItemized', value)}
                        question={'Would you like to upload your tarrif sheets here ? (Optional)'}
                        />
                </Row>
                <Row className="my-5">
                    <Handles
                        rows={packagesAndTariff.data.mediaLibraryLinks}
                        setRows={(rows) => handleSave5('mediaLibraryLinks', rows)}
                        errors={errors}
                        havePlatform = {false}
                        label = 'Can you provide us with access to a Media Library for our marketing purposes? (google drive, BOX, etc.) == packages & upload.'
                        description = 'Provide access to your Media Library for marketing purposes.'
                    />
                </Row>
                <Row className="my-4">
                    <Col md={4}></Col>
                    <Col md={8}>
                        <BootstrapButton className='m-4' variant="secondary" onClick={() => setCurrentStage(4)}>Back</BootstrapButton>
                        <BootstrapButton variant="primary" onClick={() => handleSubmit()}>Save and Continue</BootstrapButton>
                    </Col>
                </Row>
            </>
        );
    /*
                        END OF PART 5
    */
    /* 
    ||||||||||||||||||||||||||||||||||PART 6|||||||||||||||||||||||||||||||||||||||||

    THIS PART IS FOR THE MAIN APP AND ONLY HAS THE NAVBAR AND THEN DECIDES WHICH STAGE
    TO RENDER BASED ON THE STATE VARIABLE currentStage
    */
    const centerTexts = ['Basic Company Information','Company Profile','Services and Operations','Contact Details','Packages & Tariff Upload'];
    return (
        <div className="App">
            <CustomNavbar centerText={centerTexts[currentStage-1]} rightText={`Section: ${currentStage}/5`}/>
            <Container className="container my-5 background-color:#F3F3F3 fill:#F3F3F3 style={{  margin-bottom: 200px; }} ">
                {currentStage === 1 && renderStage1()}
                {currentStage === 2 && renderStage2()}
                {currentStage ===3 && renderStage3()}
                {currentStage ===4 && renderStage4()}
                {currentStage ===5 && renderStage5()}
                 <div style={{ height: '50px' }}></div>
            </Container>
            <ToastContainer position="top-center" className="p-3">
                <Toast show={showConfirmationToast} bg="light" style={{ width: '400px', margin: '0 auto' }}>
                    <Toast.Body>
                    <div style={{ backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
                        <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold' }}>Changes will be made. Do you want to proceed?</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                        <BootstrapButton 
                        onClick={() => handleToastConfirmation(false)} 
                        variant="danger" 
                        size="sm" 
                        style={{ marginRight: '20px', width: '100px' }}
                        >
                        No
                        </BootstrapButton>
                        <BootstrapButton 
                        onClick={() => handleToastConfirmation(true)} 
                        variant="success" 
                        size="sm" 
                        style={{ width: '100px' }}
                        >
                        Yes
                        </BootstrapButton>
                    </div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}
            >
                <Toast.Header style={{ backgroundColor: 'red', color: 'white' }}>
                    <strong className="mr-auto">Validation Error</strong>
                </Toast.Header>
                <Toast.Body style={{ backgroundColor: 'white', color: 'red' }}>
                    Please fill in all fields correctly.
                </Toast.Body>
            </Toast>
        </div>
    );
    /*
                    END OF PART 6
    */      
}
export default MainForm;