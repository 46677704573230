import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const Ellipse = ({ 
  imageSrc, 
  backgroundColor, 
  opacity, 
  tooltipText, 
  onClickFunction, 
  isClickable = false
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <svg 
        width="100" 
        height="100" 
        viewBox="0 0 100 100" 
        xmlns="http://www.w3.org/2000/svg"
        onClick={isClickable ? onClickFunction : null} 
        style={{ cursor: isClickable ? 'pointer' : 'default' }}
      >
        <ellipse cx="50" cy="50" rx="50" ry="50" fill={backgroundColor} style={{ opacity }} />
        <image href={imageSrc} x="25" y="25" height="50px" width="50px" />
      </svg>
    </OverlayTrigger>
  );
};

export default Ellipse;
