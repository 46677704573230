import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';



function isValidUrl(url) {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
}

const platformsList = ["Instagram", "LinkedIn", "Facebook"];

const Handles = ({ rows, setRows, errors,havePlatform = true,label = 'Official Company Social Media Handles'}) => {
    // console.log("START H")
    // console.log(rows)
    // console.log("END H")
    const [localErrors, setLocalErrors] = useState(errors || {});
    const [otherPlatform, setOtherPlatform] = useState('');
    const dropdownRef = useRef(null);

    const validateRows = (rows) => {
        const newErrors = {};
        rows.forEach((row, index) => {
            const checker = isValidUrl(row.url);
            console.log(checker);
            
            if(!checker)
                {
                    newErrors[`platform_${index}`] = '* Please enter a valid URL';  
                }
            if (!row.platform && havePlatform) {
                newErrors[`platform_${index}`] = '* Please select a platform';
            }
            if (!row.url) {
                newErrors[`url_${index}`] = '* Please enter a URL/Link';
            }
            if (havePlatform && rows.filter(r => r.platform.toLowerCase() === row.platform.toLowerCase()).length > 1) {
                newErrors[`platform_${index}`] = '* Platform already exists. Please enter a new platform.';
            }
        });
        return newErrors;
    };

    const addRow = () => {
        const newErrors = validateRows(rows);
        if (Object.keys(newErrors).length === 0) {
            setRows([...rows, { platform: '', url: '' }]);
            setOtherPlatform('');
            setLocalErrors({});
        } else {
            setLocalErrors(newErrors);
        }
    };

    const handleChange = (index, key, value) => {
        const newRows = [...rows];
        newRows[index][key] = value;
        setRows(newRows);
    };

    const handleSelect = (index, eventKey, event) => {
        handleChange(index, 'platform', event.target.innerText);
        setOtherPlatform('');
    };

    const handleOtherChange = (index, value) => {
        handleChange(index, 'platform', value);
        setOtherPlatform(value);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            const otherText = e.target.value;
            if (rows.some(row => row.platform.toLowerCase() === otherText.toLowerCase())) {
                setLocalErrors({ [`platform_${index}`]: '* Platform already exists. Please enter a new platform.' });
            } else {
                handleChange(index, 'platform', otherText);
                dropdownRef.current.click();
            }
        }
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    useEffect(() => {
        setLocalErrors(errors);
    }, [errors]);

    return (
        <div className="form-content">
            <Row>
                <Col md={4}>
                    <label>{label}</label>
                </Col>
                <Col md={8}>
                    {rows.map((row, index) => (
                        <React.Fragment key={index}>
                            <Row className="mb-2">
                                {havePlatform && (
                                <Col md={3}>
                                    <Dropdown onSelect={(eventKey, event) => handleSelect(index, eventKey, event)} className="custom-dropdown">
                                        <Dropdown.Toggle variant="light" id="dropdown-basic" ref={dropdownRef} className="custom-dropdown-toggle">
                                            {row.platform.slice(0,15) || "Select Platform"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {platformsList.map((platform, idx) => (
                                                <Dropdown.Item eventKey={idx} key={idx}>{platform}</Dropdown.Item>
                                            ))}
                                            <Dropdown.Divider />
                                            <Dropdown.ItemText className="custom-dropdown-item-text">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Other"
                                                    value={otherPlatform}
                                                    onChange={(e) => handleOtherChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    className="custom-dropdown-input"
                                                    style={{ whiteSpace: 'nowrap', overflow: 'auto' }}
                                                    autoComplete='off'
                                                />
                                            </Dropdown.ItemText>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                )}
                                <Col md={7}>
                                    <Form.Control
                                        type="text"
                                        placeholder="example.com"
                                        value={row.url}
                                        onChange={(e) => handleChange(index, 'url', e.target.value)}
                                        autoComplete='off'
                                    />
                                </Col>
                              {index >0 &&  (<Col md={2}>
                                    <img 
                                        src={`${process.env.PUBLIC_URL}/images/delete.svg`} 
                                        alt="Delete" 
                                        className="delete-icon" 
                                        onClick={() => handleDeleteRow(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Col>)}
                            </Row>
                        </React.Fragment>
                    ))}
                  
                    <Row className="mb-2">
                        <Col md={2}>
                            <Button onClick={addRow} variant="outline-primary">+ Add</Button>
                        </Col>
                        <Col md={10}>

                            {Object.keys(localErrors).filter(key => key.startsWith('platform') || key.startsWith('url')).map((key, idx) => (
                                <small key={idx} className="text-danger d-block">{localErrors[key]}</small>
                            ))}
                        </Col>
                    </Row>
        
                </Col>
            </Row>
        </div>
    );
};

export default Handles;
