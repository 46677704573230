import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const TwoText = ({ inputText, placeholder1, placeholder2, description, rowsData, setRowsData, errors }) => {
    const [rows, setRows] = useState(rowsData? rowsData : [{ association: '', membershipStartYear: '', error: '' }]);

    useEffect(() => {
        if (rowsData && rowsData.length > 0) {
            setRows(rowsData);
        }
    }, [rowsData]);

    useEffect(() => {
        if (errors) {
            const newRows = rows.map((row, index) => ({
                ...row,
                error: errors[`association_${index}`] || errors[`membershipStartYear_${index}`] || ''
            }));
            setRows(newRows);
        }
    }, [errors]);

    const addRow = () => {
        let isValid = true;
        const newRows = rows.map(row => {
            if (!row.association) {
                row.error = '* Please select an association';
                isValid = false;
            } else if (!row.membershipStartYear) {
                row.error = '* Please enter a year';
                isValid = false;
            } else {
                row.error = '';
            }
            return row;
        });

        setRows(newRows);

        if (isValid) {
            const updatedRows = [...rows, { association: '', membershipStartYear: '', error: '' }];
            setRows(updatedRows);
            if (setRowsData) setRowsData(updatedRows);
        }
    };

    const handleTextChange = (index, key, value, maxVal) => {
        if (maxVal !== -1 && value.length > maxVal) {
            return;
        }
    
        const newRows = [...rows];
        newRows[index][key] = value;
        newRows[index].error = ''; // Clear the error when user starts typing
        setRows(newRows);
    
        if (setRowsData) {
            setRowsData(newRows);
        }
    };
    

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
        if (setRowsData) setRowsData(newRows);
    };

    return (
        <div className="form-content">
            <Row>
                <Col md={4}>
                    <label>{inputText}</label>
                </Col>
                <Col md={8}>
                    {rows.map((row, index) => (
                        <React.Fragment key={index}>
                            <Row className="">
                                <Col md={7}>
                                    <input
                                        type="text"
                                        placeholder={placeholder1}
                                        value={row.association}
                                        onChange={(e) => handleTextChange(index, 'association', e.target.value,-1)}
                                        className="form-control"
                                        autoComplete='off'
                                    />
                                </Col>
                                <Col md={index >0 ? 4: 5}>
                                    <input
                                        type="number"
                                        placeholder={placeholder2}
                                        value={row.membershipStartYear}
                                        onChange={(e) => handleTextChange(index, 'membershipStartYear', e.target.value,4)}
                                        className="form-control"
                                        autoComplete='off'
                                    />
                                </Col>
                                {index >0 && (
                                <Col md={1}>
                                    <img 
                                        src={`${process.env.PUBLIC_URL}/images/delete.svg`} 
                                        alt="Delete" 
                                        className="delete-icon" 
                                        onClick={() => handleDeleteRow(index)}
                                        style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                                    />
                                </Col>
                                )}
                            </Row>
                            <Row>
                                <Col md={8}>
                                    {row.error && (
                                        <small className="text-danger">{row.error}</small>
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
                    <Row>
                        <Col md={8}>
                            {!rows.some(row => row.error) && (
                                <small className="wide-small">{description}</small>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button onClick={addRow} variant="outline-primary">+ Add</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default TwoText;
