import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import InputGroup from 'react-bootstrap/InputGroup';


const CustomDropdown = ({ selectedValue, setSelectedValue, items = [], placeholder, getItemLabel, getItemValue }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Memoize the getItemLabel and getItemValue functions
    const memoizedGetItemLabel = useCallback(getItemLabel, []);
    const memoizedGetItemValue = useCallback(getItemValue, []);

    useEffect(() => {
        setFilteredItems(
            items.filter(item =>
                memoizedGetItemLabel(item).toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, items, memoizedGetItemLabel]);

    useEffect(() => {
        if (selectedValue) {
            const selectedItem = items.find(item => memoizedGetItemValue(item) === selectedValue);
            if (selectedItem) {
                setSearchTerm(memoizedGetItemLabel(selectedItem));
            }
        } else {
            setSearchTerm('');
        }
    }, [selectedValue, items, memoizedGetItemLabel, memoizedGetItemValue]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setIsDropdownOpen(true);
    };

    const handleSelectChange = (item) => {
        setSelectedValue(memoizedGetItemValue(item));
        setSearchTerm(memoizedGetItemLabel(item));
        setIsDropdownOpen(false);
    };

    const handleInputFocus = () => {
        setIsDropdownOpen(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownOpen(false);
        }, 100);
    };

    const handleClearSelection = () => {
        setSelectedValue('');
        setSearchTerm('');
    };

    return (
        <Col>
            <InputGroup className="custom-dropdown">
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className="custom-input"
                    autoComplete='off'
                />
                {searchTerm && (
                    <div className="clear-button" onClick={handleClearSelection}>
                        &times;
                    </div>
                )}
                {isDropdownOpen && (
                    <div className="dropdown-menu show custom-dropdown-menu">
                        {filteredItems.map((item, index) => (
                            <Dropdown.Item
                                key={index}
                                onMouseDown={() => handleSelectChange(item)}
                                className="dropdown-item"
                                autoComplete='off'
                            >
                                {memoizedGetItemLabel(item)}
                            </Dropdown.Item>
                        ))}
                    </div>
                )}
            </InputGroup>
        </Col>
    );
};

export default CustomDropdown;
