import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomDropdown from './CustomDropdown';
import { countries } from './Countries';

const OwnOfficeSetupDetails = ({ initialData1, initialData2, setRowsData1, setRowsData2, errors = {}, supportingText, countryList, name1, name2 }) => {
    const [rows1, setRows1] = useState(
        initialData1[0].country ? initialData1 : countryList.map(country => {
            const matchedCountry = countries.find(c => c.label === country);
            return { 
                country, 
                code1: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                phoneNumber1: '', 
                code2: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                phoneNumber2: '' 
            };
        })
    );

    const [rows2, setRows2] = useState(
        initialData2[0].country ? initialData2 : countryList.map(country => {
            const matchedCountry = countries.find(c => c.label === country);
            return { 
                country, 
                code1: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                phoneNumber1: '', 
                code2: matchedCountry ? `${matchedCountry.code} ${matchedCountry.phone}` : '', 
                phoneNumber2: '' 
            };
        })
    );

    const [sameContact, setSameContact] = useState(null);
    const [formError, setFormError] = useState('');

    const handleChange1 = (index, key, value) => {
        const updatedRows = rows1.map((row, i) => (i === index ? { ...row, [key]: value } : row));
        setRows1(updatedRows);
        setRowsData1(updatedRows);
        if (sameContact) {
            setRows2(updatedRows);
            setRowsData2(updatedRows);
        }
    };

    useEffect(() => {
        if (!initialData1[0].country || !initialData2[0].country) {
            return;
        }
        
        const isSame = initialData1.every((row, index) => 
            row.code1 === initialData2[index].code1 && 
            row.phoneNumber1 === initialData2[index].phoneNumber1 &&
            row.code2 === initialData2[index].code2 &&
            row.phoneNumber2 === initialData2[index].phoneNumber2
        );
    
        setSameContact(isSame);
    }, [initialData1, initialData2]);

    const handleChange2 = (index, key, value) => {
        const updatedRows = rows2.map((row, i) => (i === index ? { ...row, [key]: value } : row));
        setRows2(updatedRows);
        setRowsData2(updatedRows);
    };

    const displayError = (key, index) => {
        const errorKey = `${key}_${index}`;
        return errors[errorKey] ? <small className="text-danger">{errors[errorKey]}</small> : null;
    };

    return (
        <div className="contact-form">
           <Row>
    <Col md={4}>
        <label>Do you have the same emergency contact for during and after office hours?</label>
    </Col>
    <Col className="radio-group d-flex align-items-center" md={8}>
        <label className="radio-option mx-2">
            <input 
                type="radio" 
                value="true" 
                checked={sameContact === true} 
                onChange={() => setSameContact(true)} 
                isInvalid={formError}
            />
            Yes
        </label>
        <label className="radio-option mx-2">
            <input 
                type="radio" 
                value="false" 
                checked={sameContact === false} 
                onChange={() => setSameContact(false)} 
                isInvalid={formError}
            />
            No
        </label>
        {formError && <small className="text-danger">{formError}</small>}
    </Col>
</Row>

            {sameContact !== null && (
                <>
                    {sameContact ? (
                        <>
                            <Row>
                                <Col md={4}>
                                    <label>
                                        Emergency contact for <strong>during</strong> and <strong>after</strong> office hours
                                    </label>
                                </Col>
                            </Row>
                            {rows1.map((row, index) => (
                                <div key={index} className="contact-form-entry mb-3">
                                    <Row className="mb-3 align-items-center">
                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            <h6>{row.country}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col md={8} className="offset-md-4">
                                            <Row>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code1}
                                                        setSelectedValue={(value) => handleChange1(index, 'code1', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name1}_code1_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name1}_code1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Phone Number"
                                                        value={row.phoneNumber1}
                                                        onChange={(e) => handleChange1(index, 'phoneNumber1', e.target.value)}
                                                        className={errors[`${name1}_phoneNumber1_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name1}_phoneNumber1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code2}
                                                        setSelectedValue={(value) => handleChange1(index, 'code2', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name1}_code2_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name1}_code2`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Whatsapp Phone"
                                                        value={row.phoneNumber2}
                                                        onChange={(e) => handleChange1(index, 'phoneNumber2', e.target.value)}
                                                        className={errors[`${name1}_phoneNumber2_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name1}_phoneNumber2`, index)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col md={4}>
                                    <label>
                                        Emergency contact for <strong>during</strong> office hours
                                    </label>
                                </Col>
                            </Row>
                            {rows1.map((row, index) => (
                                <div key={index} className="contact-form-entry mb-3">
                                    <Row className="mb-3 align-items-center">
                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            <h6>{row.country}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col md={8} className="offset-md-4">
                                            <Row>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code1}
                                                        setSelectedValue={(value) => handleChange1(index, 'code1', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name1}_code1_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name1}_code1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Phone Number"
                                                        value={row.phoneNumber1}
                                                        onChange={(e) => handleChange1(index, 'phoneNumber1', e.target.value)}
                                                        className={errors[`${name1}_phoneNumber1_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name1}_phoneNumber1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code2}
                                                        setSelectedValue={(value) => handleChange1(index, 'code2', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name1}_code2_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name1}_code2`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Whatsapp Number"
                                                        value={row.phoneNumber2}
                                                        onChange={(e) => handleChange1(index, 'phoneNumber2', e.target.value)}
                                                        className={errors[`${name1}_phoneNumber2_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name1}_phoneNumber2`, index)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Row>
                                <Col md={4}>
                                    <label>
                                        Emergency contact for <strong>after</strong> office hours
                                    </label>
                                </Col>
                            </Row>
                            {rows2.map((row, index) => (
                                <div key={index} className="contact-form-entry mb-3">
                                    <Row className="mb-3 align-items-center">
                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            <h6>{row.country}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col md={8} className="offset-md-4">
                                            <Row>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code1}
                                                        setSelectedValue={(value) => handleChange2(index, 'code1', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name2}_code1_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name2}_code1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Phone Number"
                                                        value={row.phoneNumber1}
                                                        onChange={(e) => handleChange2(index, 'phoneNumber1', e.target.value)}
                                                        className={errors[`${name2}_phoneNumber1_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name2}_phoneNumber1`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <CustomDropdown
                                                        selectedValue={row.code2}
                                                        setSelectedValue={(value) => handleChange2(index, 'code2', value)}
                                                        items={countries}
                                                        placeholder="Select Code"
                                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                                        getItemValue={(item) => `${item.code} ${item.phone}`}
                                                        className={errors[`${name2}_code2_${index}`] ? 'border-danger' : ''}
                                                    />
                                                    {displayError(`${name2}_code2`, index)}
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Whatsapp Number"
                                                        value={row.phoneNumber2}
                                                        onChange={(e) => handleChange2(index, 'phoneNumber2', e.target.value)}
                                                        className={errors[`${name2}_phoneNumber2_${index}`] ? 'border-danger' : ''}
                                                        autoComplete='off'
                                                    />
                                                    {displayError(`${name2}_phoneNumber2`, index)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default OwnOfficeSetupDetails;
