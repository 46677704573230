import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomDropdown from './CustomDropdown';
import { countries } from './Countries';
 
function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
 
const ContactDetails = ({ label, initialData, setRowsData, errors = {}, supportingText, placeholder1 = 'Enter Name', placeholder2 = 'Enter Email', withAdd = false }) => {
    const [rows, setRows] = useState(initialData.length >0 ? initialData : [{ code: '', name: '', phoneNumber: '', email: '' }]);
    const [localErrors, setLocalErrors] = useState({});
  

    useEffect(() =>{
        if(initialData.length> 0 ){
        setRows(initialData);
        }
    },[initialData]);


    useEffect(() => {
        setLocalErrors(
            Object.fromEntries(
                Object.entries(errors).filter(([key]) => key.startsWith('companyOwners_'))
            )
        );
    }, [errors]);
 
    const validateRows = (rows) => {
        const newErrors = {};
        rows.forEach((row, index) => {
            if (!row.name) newErrors[`companyOwners_name_${index}`] = '*Name is required';
            if (!row.email || !isEmail(row.email)) newErrors[`companyOwners_email_${index}`] = '*Email is required';
        });
        return newErrors;
    };
 
    const handleAddRow = () => {
        const newErrors = validateRows(rows);
        if (Object.keys(newErrors).length === 0) {
            const updatedRows = [...rows, { code: '', name: '', phoneNumber: '', email: '' }];
            setRows(updatedRows);
            setLocalErrors({});
        } else {
            setLocalErrors(newErrors);
        }
    };
 
    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        setRowsData(updatedRows); // Update parent component with new rows
        const newErrors = validateRows(updatedRows);
        setLocalErrors(newErrors);
    };
 
    const handleChange = (index, key, value) => {
        const updatedRows = rows.map((row, i) => (i === index ? { ...row, [key]: value } : row));
        setRows(updatedRows);
        setRowsData(updatedRows); // Update parent component with new rows
        const newErrors = { ...localErrors };
        delete newErrors[`companyOwners_${key}_${index}`];
        setLocalErrors(newErrors);
    };
 
    return (
        <div className="contact-form">
            {rows.map((row, index) => (
                <div key={index} className="contact-form-entry mb-3">
                    <Row className="mb-3 align-items-center">
                        <Col md={4}>
                            {index === 0 && <label>{label}</label>}
                        </Col>
                        <Col md={8}>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Form.Control
                                        type="text"
                                        placeholder={placeholder1}
                                        value={row.name}
                                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                                        isInvalid={!!localErrors[`companyOwners_name_${index}`]}
                                        autoComplete='off'
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Control
                                        type="email"
                                        placeholder={placeholder2}
                                        value={row.email}
                                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                                        isInvalid={!!localErrors[`companyOwners_email_${index}`]}
                                        autoComplete='off'
                                    />
                                </Col>
                                <Col md={3}>
                                    <CustomDropdown
                                        selectedValue={row.code}
                                        setSelectedValue={(value) => handleChange(index, 'code', value)}
                                        items={countries}
                                        placeholder="Select Code"
                                        getItemLabel={(item) => `${item.code} ${item.phone}`}
                                        getItemValue={(item) => item.phone}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Phone"
                                        value={row.phoneNumber}
                                        onChange={(e) => handleChange(index, 'phoneNumber', e.target.value)}
                                        autoComplete='off'
                                    />
                                </Col>
                                {index > 0 && (
                                    <Col md={1} className="d-flex align-items-center">
                                        <Button variant="link" onClick={() => handleRemoveRow(index)} className="p-0">
                                            <img src={`${process.env.PUBLIC_URL}/images/delete.svg`} alt="Delete" style={{ width: '20px', height: '20px' }} />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>
            ))}
            {withAdd && (
                <Row>
                    <Col md={{ span: 8, offset: 4 }} className="text-right">
                        <Button variant="outline-primary" onClick={handleAddRow}>
                            + Add
                        </Button>
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={{ span: 8, offset: 4 }}>
                    {Object.keys(localErrors).length > 0 && (
                        <small className="text-danger">*Please fill all required fields</small>
                    )}
                    {Object.keys(localErrors).length === 0 && (
                        <small className="form-text text-muted">{supportingText}</small>
                    )}
                </Col>
            </Row>
        </div>
    );
};
 
export default ContactDetails;
 
 