import React from 'react';
import Select, { components } from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ClearIndicator = (props) => {
  const {
    children = <span>&times;</span>,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={{ cursor: 'pointer', padding: '0 8px' }}>
      {children}
    </div>
  );
};

const SingleSelect = ({ inputText, description, selectedValue, setSelectedValue, options, placeholder, isError, errorText, removeLabel = false }) => {
  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption ? selectedOption.value : '');
  };

  return (
    <div className="form-content">
      <Row>
        {!removeLabel && (
          <Col md={4}>
            <label>{inputText}</label>
          </Col>
        )}
        <Col md={ removeLabel ?  12 : 8 }>

          <Select
            options={options}
            value={options.find(option => option.value === selectedValue)}
            onChange={handleChange}
            placeholder={placeholder}
            className={`single-select-dropdown ${isError ? 'is-invalid' : ''}`}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            components={{ ClearIndicator }}
            styles={{
              input: (provided) => ({
                ...provided,
                autoComplete: 'off',
              }),
              control: (provided) => ({
                ...provided,
                boxShadow: 'none',
                '&:hover': { border: 'none' },
                minHeight: '35px', // Set a fixed height to prevent jumping
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999, // Ensure the menu is on top
                transition: 'opacity 0.2s ease', // Smooth transition
                opacity: 1,
              }),
              menuList: (provided) => ({
                ...provided,
                maxHeight: '200px', // Limit the height of the menu
                overflowY: 'auto', // Add scroll for overflow
                WebkitOverflowScrolling: 'touch', // Smooth scrolling on mobile
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent', // Remove grey box
                borderRadius: '0px', // No border radius
                padding: '0px', // No padding
                margin: '0px', // Adjust margin to remove right margin
              }),
              valueContainer: (provided) => ({
                ...provided,
                color: '#495057'
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#6c757d'
              }),
              clearIndicator: (provided) => ({
                ...provided,
                cursor: 'pointer'
              })
            }}
            isClearable
          />
          {isError && <small className="text-danger">{errorText}</small>}
          {!isError && <small className="wide-small">{description}</small>}
        </Col>
      </Row>
    </div>
  );
};

export default SingleSelect;
