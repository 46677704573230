import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';

const DaysOptions = ({ label, countryList, initialData, setRowsData, isError, errorText }) => {
    const [rows, setRows] = useState(
        initialData.day ? initialData : countryList.map(country => ({ country:country, days: ['MON', 'TUE', 'WED', 'THU', 'FRI'] }))
    );

    useEffect(() => {
        if (setRowsData) {
            setRowsData(rows);
        }
    }, []);


    const handleCheckboxChange = (index, day) => {
        const updatedRows = rows.map((row, i) => {
            if (i === index) {
                const updatedDays = row.days.includes(day)
                    ? row.days.filter(item => item !== day)
                    : [...row.days, day];
                return { ...row, days: updatedDays };
            }
            return row;
        });
        setRows(updatedRows);
        setRowsData(updatedRows); // Update the parent component immediately
    };

    const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    return (
        <div className="days-options">
            <Row className="mb-3">
                <Col md={4}>
                    <label>{label}</label>
                </Col>
            </Row>
            {rows.map((row, index) => (
                <div key={index} className="days-options-entry mb-3">
                    <Row className="mb-3 align-items-center">
                        <Col md={4}></Col>
                        <Col md={8}>
                            <h6>{row.country}</h6>
                        </Col>
                    </Row>
                    <Row className="mb-3 align-items-center">
                        <Col md={8} className="offset-md-4">
                            <Form>
                                {daysOfWeek.map((day, idx) => (
                                    <Form.Check
                                        key={idx}
                                        type="checkbox"
                                        id={`checkbox-${index}-${day}`}
                                        label={day}
                                        checked={row.days.includes(day)}
                                        onChange={() => handleCheckboxChange(index, day)}
                                        inline
                                        autoComplete='off'
                                    />
                                ))}
                            </Form>
                            {isError && <small className="text-danger">{errorText}</small>}
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default DaysOptions;
