import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomDropdown from './CustomDropdown'; 
import { countries } from './Countries';

const ContactForm = ({ selectedPrefix, setSelectedPrefix, name, setName, phoneNumber, setPhoneNumber, email, setEmail, designation, setDesignation, isError, errorText, supportingText, disable = false }) => {
    return (
        <div className="contact-form">
            <Row>
                <Col md={4}>
                    <label>Your details (Company Representative)</label>
                </Col>
                <Col md={8}>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className={isError && !name ? 'is-invalid' : ''}
                                autoComplete='off'
                            />
                        </Col>
                        <Col md={2}>
                            <CustomDropdown
                                selectedValue={selectedPrefix}
                                setSelectedValue={setSelectedPrefix}
                                items={countries}
                                placeholder="Select Code"
                                getItemLabel={(item) => `${item.code} ${item.phone}`}
                                getItemValue={(item) => item.phone}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                type="number"
                                placeholder="Enter Phone"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className={isError && !phoneNumber ? 'is-invalid' : ''}
                                autoComplete='off'
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                disabled={disable ? true : false}
                                onChange={(e) => setEmail(e.target.value)}
                                className={isError && !email ? 'is-invalid' : ''}
                                autoComplete='off'
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Designation"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                className={isError && !designation ? 'is-invalid' : ''}
                                autoComplete='off'
                            />
                        </Col>
                        <Col md={12}>
                            {(isError &&  
                                <small className="text-danger">
                                    {errorText}
                                </small>) 
                                || 
                                (<small className="form-text text-muted">
                                    {supportingText}
                                </small>)
                            }   
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ContactForm;
