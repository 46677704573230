import React from 'react';
import { Toast } from 'react-bootstrap';

const CustomToast = ({ show, onClose, headerColor, headerMessage, bodyColor, bodyMessage }) => {
  return (
    <Toast
      onClose={onClose}
      show={show}
      delay={5000}
      autohide
      style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}
    >
      <Toast.Header className={`bg-${headerColor} text-white`}>
        <strong className="mr-auto">{headerMessage}</strong>
      </Toast.Header>
      <Toast.Body className={`bg-light text-${bodyColor}`}>
        {bodyMessage}
      </Toast.Body>
    </Toast>
  );
};

export default CustomToast;