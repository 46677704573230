import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import axios from 'axios';
import MainForm from './MainForm';
import ReviewPage from './ReviewPage';
import ThankYou from './ThankYou';
import { API } from './API';
import RequestLogin from './components/RequestLogin.js';

const RedirectToPartnerForm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/partner-form');
  }, [navigate]);
  return null;
};

function ProtectedRoute({ children }) {
  const [authStatus, setAuthStatus] = useState({ checked: false, isAuthenticated: false });
  const navigate = useNavigate();
  useEffect(() => {  
      API.get('/DMC/authenticated')
        .then(response => {
          console.log('Authenticated successfully');
          setAuthStatus({ checked: true, isAuthenticated: response.data.isAuthenticated });
        })
        .catch((error) => {
          console.log('Authentication failed:', error);
          setAuthStatus({ checked: true, isAuthenticated: false });
        });
   
  }, []);

  if (!authStatus.checked) {
    return <div>Loading...</div>;
  }
  if (!authStatus.isAuthenticated) {
    return navigate('/partner-login-with-email');
  }
  return children;
}

const App = () => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RedirectToPartnerForm />} />
          <Route path="/partner-form" element={<ProtectedRoute><MainForm /></ProtectedRoute>} />
          <Route path="/partner-login-with-email" element={<RequestLogin />} />                   
          <Route path="/review" element={<ReviewPage />} />
          <Route path="/thank-you" element={<ProtectedRoute><ThankYou /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;