import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Text_input = ({ inputText, supportingText, inputValue, setInputValue, isError, errorText, placeholder, isTextarea = false,type = 'InputText',maxLength = -1,doPlaceHolder = true,disabled = false }) => {
    const handleInputChange = (event) => {
        if (maxLength === -1 || event.target.value.length <= maxLength) {
            setInputValue(event.target.value);
        }
    };
    return (
        <>
            <Col md={4}>
                <label className='input'>{inputText}</label>
            </Col>
            <Col md={8}>
                <Row>
                    <Col md={12}>
                        {isTextarea ? (
                            <textarea
                                id='InputText'
                                className='form-control'
                                placeholder={placeholder}
                                value={inputValue}
                                onChange={handleInputChange}
                                rows={4}
                                autoComplete='off'
                            />
                        ) : (
                            <input
                                type={type}
                                id='InputText'
                                className='form-control'
                                placeholder={placeholder }
                                value={inputValue}
                                onChange={handleInputChange}
                                maxLength={maxLength!== -1 ? maxLength: undefined}
                                disabled = {disabled ? true:false}
                                autoComplete='off'

                            />
                        )}
                        {isError ? (
                            <small className="text-danger">{errorText}</small>
                        ) : (
                            <small className="form-text text-muted">{supportingText}</small>
                        )}
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Text_input;
