import React, { createContext, useContext } from 'react';

const SaveContext = createContext();

export const SaveProvider = ({ children, handleSave }) => {
    return (
        <SaveContext.Provider value={handleSave}>
            {children}
        </SaveContext.Provider>
    );
};

export const useSave = () => {
    return useContext(SaveContext);
};
