import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SingleSelect from './SingleSelect';

const CountryTextInput = ({ label, countryPlaceholder, textPlaceholder, addLabel, countries, errors = {}, setRowsData, rowsData = [{ country: '', text: '' }] }) => {
    const [rows, setRows] = useState(rowsData[0].country ? rowsData : [{ country: '', text: '' }]);
    const [rowErrors, setRowErrors] = useState(errors);

    useEffect(() => {
        if (setRowsData) {
            setRowsData(rows);
        }
    }, [rows, setRowsData]);

    const handleAddRow = () => {
        const newErrors = validateRows();
        if (Object.keys(newErrors).length === 0) {
            const newRow = { country: '', text: '' };
            const updatedRows = [...rows, newRow];
            setRows(updatedRows);
        } else {
            setRowErrors(newErrors);
        }
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
    };

    const handleCountryChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].country = value;
        setRows(updatedRows);
        clearError(index, 'country');
    };

    const handleTextChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].text = value;
        setRows(updatedRows);
        clearError(index, 'text');
    };

    const clearError = (index, field) => {
        const newErrors = { ...rowErrors };
        delete newErrors[`${field}_${index}`];
        setRowErrors(newErrors);
    };

    const validateRows = () => {
        const newErrors = {};
        rows.forEach((row, index) => {
            if (!row.country) {
                newErrors[`country_${index}`] = 'Country is required';
            }
            if (!row.text) {
                newErrors[`text_${index}`] = 'Text is required';
            }
        });
        return newErrors;
    };

    return (
        <div className="country-text-input">
            <Row>
                <Col md={4}>
                    <label>{label}</label>
                </Col>
                <Col md={8}>
                    {rows.map((row, index) => (
                        <div key={index} className="mb-3">
                            <Row>
                                <Col md={3}>
                                    <SingleSelect
                                        inputText=""
                                        placeholder={countryPlaceholder}
                                        selectedValue={row.country}
                                        setSelectedValue={(value) => handleCountryChange(index, value)}
                                        options={countries}
                                        isError={!!rowErrors[`country_${index}`]}
                                        errorText={rowErrors[`country_${index}`]}
                                        removeLabel={true}
                                    />
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        type="text"
                                        placeholder={textPlaceholder}
                                        value={row.text}
                                        onChange={(e) => handleTextChange(index, e.target.value)}
                                        isInvalid={!!rowErrors[`text_${index}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {rowErrors[`text_${index}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                {index > 0 && (
                                    <Col md={1} className="d-flex align-items-center">
                                        <Button variant="link" onClick={() => handleRemoveRow(index)} className="p-0">
                                            <img src={`${process.env.PUBLIC_URL}/images/delete.svg`} alt="Delete" className="delete-icon" />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            {index === rows.length - 1 && (
                                <Button variant="outline-primary" onClick={handleAddRow} className="align-self-start mt-2">
                                    + {addLabel}
                                </Button>
                            )}
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default CountryTextInput;
