import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Image } from '@react-pdf/renderer';
import { useState,useEffect } from 'react';
import { compareProfiles } from './CompareObjects';

// Define styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    padding: 72,
    fontFamily: 'Helvetica',
    position: 'relative',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  questionContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  question: {
    width: '45%',
    paddingRight: 10,
    fontSize: 10,
  },
  answer: {
    width: '50%',
    fontWeight: 'bold',
    paddingRight: 10,
    fontSize: 10,
  },
  tableContainer: {
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: '100%',
    margin: '10px 0',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#F3F3F3',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#F3F3F3',
    backgroundColor: '#343a40',
    color: '#ffffff',
    textAlign: 'left',
    padding: 8,
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#F3F3F3',
    textAlign: 'left',
    padding: 8,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  tableCell: {
    fontSize: 10,
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 72,
    right: 72,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLeft: {
    position: 'absolute',
    left: 10,
    bottom:10,
    fontSize: 8
  },
  footerText: {
    fontSize: 8,
    color: '#888888',
  },
  footerLink: {
    fontSize:8,
    color: '#007bff',
    textDecoration: 'none',
    marginHorizontal: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    right: 10,
  },
  headerLogo: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 100, // Increase the width
    height: 50, // Adjust the height to maintain aspect ratio
    objectFit: 'contain', // Maintain aspect ratio
  },
  watermark: {
    position: 'absolute',
    fontSize: 90,
    color: 'rgba(0,0,0,0.1)',
    transform: 'rotate(-45deg)',
    top: '50%',
    left: '50%',
    marginTop: -60, // half of fontSize to center vertically
    marginLeft: -260, // approximate half of the text width to center horizontally
  },
});

const watermarkText = 'CONFIDENTIAL';
const generateWatermark = () => (
  <Text style={styles.watermark} key="watermark">
    {watermarkText}
  </Text>
);
const getStringBeforeT = (inputString) => {
  // Check if inputString is defined before trying to get substring
  if (inputString && typeof inputString === 'string') {
    // Find the index of 'T'
    const index = inputString.indexOf('T');
    // If 'T' is found, return the substring before 'T'
    if (index !== -1) {
      return inputString.substring(0, index);
    }
  }
  // Return the entire string if 'T' is not found
  return inputString;
};
const renderHeader = () => (
  <Image style={styles.headerLogo} src={`${process.env.PUBLIC_URL}/images/marcopolo-logo.png`} />
);
const renderFooter = () => (
  <>
    <Text style={styles.footerLeft}>
      <Link src="mailto:partner@marcopolo-holidays.com" style={styles.footerLink} fixed>partner@marcopolo-holidays.com</Link>
      &nbsp;|&nbsp;
      <Link src="tel:+97165626604" style={styles.footerLink} fixed>+971 656 26604</Link>
    </Text>
    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
      <>
        <Text style={styles.pageNumber} fixed>{`${pageNumber} / ${totalPages}`}</Text>
      </>
    )} fixed />
  </>
);

const MyDocument = ({ profile,finalProfile }) => {
  let questionNumber = 1;
  const pages = [];
  let currentPageContent = [];
  let currentPageIndex = 0;
  let currentPageHeight = 0;
  const A4_PAGE_HEIGHT = 842; // Adjust page height limit as needed
  const CONTENT_MARGIN = 170;
  const [ changedIds, setchangedIds] = useState(false);
  const addPage = (content, pageIndex) => {
    pages.push(
      <Page size='A4' style={styles.page} key={pageIndex}>
        {renderHeader()}
        {generateWatermark()}
        <View>
          {content}
        </View>
        {renderFooter()}
      </Page>
    );
  };
  useEffect(() => {
    if (!finalProfile) {
      return;
    }    
    const changes = compareProfiles(profile, finalProfile);
    setchangedIds(changes);  
  }, []); // Empty dependency array means this effect runs once on mount
  const estimateContentHeight = (content) => {  
    if (content.type === View && content.props.style === styles.questionContainer) {
      return 25; // RenderQuestionAnswer component height
    }  
    if (content.type === View && content.props.style === styles.tableContainer) {
      const numRows = content.props.children[1].props.children.length;
      return (numRows + 1) * 50; // 10 for each row plus 1 for the header
    }  
    return 10; // Default single line height
  };
  const addContentToPage = (content) => {
    const contentHeight = estimateContentHeight(content);
    if (currentPageHeight + contentHeight > A4_PAGE_HEIGHT - CONTENT_MARGIN) {
      addPage(currentPageContent, currentPageIndex);
      currentPageContent = [];
      currentPageHeight = 0;
      currentPageIndex++;
    }
    currentPageContent.push(content);
    currentPageHeight += contentHeight;
  };
  // Render each question and answer as per the original format
  const renderQuestionAnswer = (label, answer) => {
    addContentToPage(
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{`${questionNumber}. ${label}`}</Text>
        <Text style={styles.answer}>{answer}</Text>
        {questionNumber++}
      </View>
    );
  };
  // Basic Company Information
  addContentToPage(
    <Text style={styles.subHeader}>Basic Company Information</Text>
  );
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.companyName')) && (
    renderQuestionAnswer('Company Legal Name:', profile.basicCompanyInfo.data.companyName)
  )}
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.yearOfIncorporation')) && (
    renderQuestionAnswer('Year of Incorporation:', profile.basicCompanyInfo.data.yearOfIncorporation)
  )}
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.isFamilyRun')) && (
    renderQuestionAnswer('Is your company a family-run business:', profile.basicCompanyInfo.data.isFamilyRun ? 'Yes' : 'No'))
  }
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.companyRepresentativeName')) && (
    renderQuestionAnswer('Company Representative Name:', profile.basicCompanyInfo.data.companyRepresentativeName)
  )}  
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.phoneNumber')) && (
    addContentToPage(
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{`${questionNumber}. Phone Number:`}</Text>
        <Link src={`tel:${profile.basicCompanyInfo.data.phoneCode}${profile.basicCompanyInfo.data.phoneNumber}`} style={styles.answer}>
          {`${profile.basicCompanyInfo.data.phoneCode} ${profile.basicCompanyInfo.data.phoneNumber}`}
        </Link>
        {questionNumber++}
      </View>
    )
  )}
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.email')) && (
    renderQuestionAnswer('Email:', profile.basicCompanyInfo.data.email)
  )}
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.designation')) && (
    renderQuestionAnswer('Designation:', profile.basicCompanyInfo.data.designation)
  )}
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.companyOwners')) && (
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Company Owners:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
          </View>
          {profile.basicCompanyInfo.data.companyOwners.map((owner, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{owner.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{owner.email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${owner.code}${owner.phoneNumber}`} style={styles.tableCell}>
                  {owner.code ? `${owner.code}${owner.phoneNumber || ''}` : ''}
                </Link>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    )
  )}    
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.areCompanyOwnersInvolved')) && (
    renderQuestionAnswer('Are the company owners actively involved in the day-to-day running of the business?', profile.basicCompanyInfo.data.areCompanyOwnersInvolved ? 'Yes' : 'No'))
  }
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.ownersInvolved')) && (
    renderQuestionAnswer('Company owners actively involved:', profile.basicCompanyInfo.data.ownersInvolved.map(owner => owner.label).join(', '))
  )}  
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.companyWebsite')) && (
    renderQuestionAnswer('Company website:', <Link src={profile.basicCompanyInfo.data.companyWebsite}>{profile.basicCompanyInfo.data.companyWebsite}</Link>)
  )}  
  {((!changedIds) || changedIds.some(change => change.id === 'basicCompanyInfo.data.platforms')) && (
    addContentToPage(
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{`${questionNumber}. Platforms:`}</Text>
        {profile.basicCompanyInfo.data.platforms.map((platform, index) => (
          <Link key={index} src={platform.url} style={styles.answer}>
            {`${platform.platform}: ${platform.url}`}
          </Link>
        ))}
        {questionNumber++}
      </View>
    )
  )}  
  // Company Profile
  addContentToPage(
    <Text style={styles.subHeader}>Company Profile</Text>
  );  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss')) && (
    renderQuestionAnswer('Member of any local or international travel industry associations:', profile.companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss ? 'Yes' : 'No'))
  }        
  if (profile.companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss && ((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.isMemberOfAnyLocalOrInternatonalTravelIndustryAss'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Travel Industry Associations:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Association</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Membership Start Year</Text>
            </View>
          </View>
          {profile.companyProfileStage.data.travelIndustryAssociations.map((association, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{association.association}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{association.membershipStartYear}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.businessLicense')) && (
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Business License:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>File Name</Text>
            </View>
          </View>
          {profile.companyProfileStage.data.businessLicense.map((license, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{license.selectedCountry}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {license.name}
                </Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    )
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.countriesProvideTour')) && (
    renderQuestionAnswer('Countries Provide Tour:', profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.countriesOwnOffices')) && (
    renderQuestionAnswer('Countries Own Offices:', profile.companyProfileStage.data.countriesOwnOffices.map(country => country.label).join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.numFullTimeEmployees')) && (
    renderQuestionAnswer('Number of Full-time Employees:', profile.companyProfileStage.data.numFullTimeEmployees)
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.handledUAEGCC')) && (
    renderQuestionAnswer('Handled UAE GCC:', profile.companyProfileStage.data.handledUAEGCC ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.GCCCountries')) && (
    renderQuestionAnswer('GCC Countries:', profile.companyProfileStage.data.GCCCountries.map(country => country.label).join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.operatesB2B')) && (
    renderQuestionAnswer('Operates B2B:', profile.companyProfileStage.data.operatesB2B ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.registrationGuidelines')) && (
    renderQuestionAnswer('Registration Guidelines:', profile.companyProfileStage.data.registrationGuidelines)
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'companyProfileStage.data.bookingsPast5Years')) && (
    renderQuestionAnswer('Bookings in Past 5 Years:', profile.companyProfileStage.data.bookingsPast5Years ? 'Yes' : 'No'))
  }
  
  // Services and Operations
  addContentToPage(
    <Text style={styles.subHeader}>Services and Operations</Text>
  );
        
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.segmentsCaterTo')) && (
    renderQuestionAnswer('Segments Catered To:', profile.servicesAndOperations.data.segmentsCaterTo.join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.sourceMarketsPercentages')) && (
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Source Markets for Incoming:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Percentage</Text>
            </View>
          </View>
          {profile.servicesAndOperations.data.sourceMarketsPercentages.map((market, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{market.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{market.percentage}%</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    )
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.supplyToTravelAgentsUAE')) && (
    renderQuestionAnswer('Do you currently supply services to travel agents in the UAE?', profile.servicesAndOperations.data.supplyToTravelAgentsUAE ? 'Yes' : 'No'))
  }
  
  if (profile.servicesAndOperations.data.supplyToTravelAgentsUAE && ((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.supplyToTravelAgentsUAE'))){
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Travel Agents in UAE:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Company Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
          </View>
          {profile.servicesAndOperations.data.UAETravelAgents.map((agent, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{agent.designation}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{agent.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${agent.selectedPrefix}${agent.phoneNumber}`} style={styles.tableCell}>
                  {`${agent.selectedPrefix} ${agent.phoneNumber}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{agent.email}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.providesSIC')) && (
    renderQuestionAnswer('Does your company operate its own seat-in-coach tours?', profile.servicesAndOperations.data.providesSIC ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.acceptsFIT')) && (
    renderQuestionAnswer('Do you accept FIT Bookings?', profile.servicesAndOperations.data.acceptsFIT ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.percentageIsFIT')) && (
    renderQuestionAnswer('What % of your annual business is FIT?', profile.servicesAndOperations.data.percentageIsFIT)
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.partnershipsWithSIC')) && (
    renderQuestionAnswer('Do you have partnerships with companies that offer SIC Tours?', profile.servicesAndOperations.data.partnershipsWithSIC ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.countriesBookSICToursThirdParty')) && (
    renderQuestionAnswer('Please select the countries where you can book SIC tours for FIT guests through 3rd party vendors:', profile.servicesAndOperations.data.countriesBookSICToursThirdParty.map(country => country.label).join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.tourGuideLanguages')) && (
    renderQuestionAnswer('Tour Guide Languages:', profile.servicesAndOperations.data.tourGuideLanguages.map(country => country.label).join(', ')))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.maintainOwnVehicleFleet')) && (
    renderQuestionAnswer('Do you own & maintain your own vehicle fleet?', profile.servicesAndOperations.data.maintainOwnVehicleFleet ? 'Yes' : 'No'))
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.vehicleDetails')) && (
    renderQuestionAnswer(profile.servicesAndOperations.data.maintainOwnVehicleFleet ? 'Enter Fleet Details:' : 'Indicate the vehicle types you offer to different group sizes:', profile.servicesAndOperations.data.vehicleDetails)
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.provideVisaRelatedServices')) && (
    renderQuestionAnswer('Do you provide visa-related services?', profile.servicesAndOperations.data.provideVisaRelatedServices ? 'Yes' : 'No'))
  }
  
  if (profile.servicesAndOperations.data.provideVisaRelatedServices && ((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.provideVisaRelatedServices'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Visa Details:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Visa Services Explained</Text>
            </View>
          </View>
          {profile.servicesAndOperations.data.visaCountryAndDescription.map((license, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{license.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{license.text}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'servicesAndOperations.data.officesOperateOutboundTravel')) && (
    renderQuestionAnswer('Do any of your offices operate as an outbound travel agency?', profile.servicesAndOperations.data.officesOperateOutboundTravel ? 'Yes' : 'No'))
  }
  
  // Contact Details
  addContentToPage(
    <Text style={styles.subHeader}>Contact Details</Text>
  );
  
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.isSingle')) && (
    renderQuestionAnswer('Single contact to send quote requests:', profile.contactDetails.data.isSingle ? 'Yes' : 'No'))
  }
  
  if (profile.contactDetails.data.contactDetailsQuotes && ((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.contactDetailsQuotes'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Email addresses for us to send quote requests:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
          </View>
          {profile.contactDetails.data.contactDetailsQuotes.map((quote, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {profile.contactDetails.data.isSingle
                    ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                    : quote.country}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quote.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${quote.code}${quote.phoneNumber}`} style={styles.tableCell}>
                  {`${quote.code}${quote.phoneNumber}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quote.email}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.isSingle1')) && (
    renderQuestionAnswer('Single escalation point of contact (Supervisor/Team Leader):', profile.contactDetails.data.isSingle1 ? 'Yes' : 'No'))
  }
  
  if (profile.contactDetails.data.escalationPointsOfContact && ((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.escalationPointsOfContact'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Escalation Point of Contact (Supervisor/Team Leader):`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
          </View>
          {profile.contactDetails.data.escalationPointsOfContact.map((contact, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {profile.contactDetails.data.isSingle1
                    ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                    : contact.country}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{contact.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${contact.code}${contact.phoneNumber}`} style={styles.tableCell}>
                  {`${contact.code}${contact.phoneNumber}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{contact.email}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.isSingle2')) && (
    renderQuestionAnswer('Single Finance/Accounting Contact:', profile.contactDetails.data.isSingle2 ? 'Yes' : 'No'))
  }
  
  if (profile.contactDetails.data.financeOrAccountingDetails &&  ((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.financeOrAccountingDetails'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Finance/Accounting:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
          </View>
          {profile.contactDetails.data.financeOrAccountingDetails.map((contact, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {profile.contactDetails.data.isSingle2
                    ? profile.companyProfileStage.data.countriesProvideTour.map(country => country.label).join(',')
                    : contact.country}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{contact.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${contact.code}${contact.phoneNumber}`} style={styles.tableCell}>
                  {`${contact.code}${contact.phoneNumber}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{contact.email}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  if (profile.contactDetails.data.officeSetupDetailsDuringOfficeHours && ((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.officeSetupDetailsDuringOfficeHours'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Office Setup Details During Office Hours:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>WhatsApp Phone Number</Text>
            </View>
          </View>
          {profile.contactDetails.data.officeSetupDetailsDuringOfficeHours.map((details, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{details.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${details.code1}${details.phoneNumber1}`} style={styles.tableCell}>
                  {`${details.code1}${details.phoneNumber1}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Link src={`https://wa.me/${details.code2}${details.phoneNumber2}`} style={styles.tableCell}>
                  {`${details.code2}${details.phoneNumber2}`}
                </Link>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  if (profile.contactDetails.data.officeSetupDetailsAfterOfficeHours && ((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.officeSetupDetailsAfterOfficeHours'))) {
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Office Setup Details After Office Hours:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Phone Number</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>WhatsApp Phone Number</Text>
            </View>
          </View>
          {profile.contactDetails.data.officeSetupDetailsAfterOfficeHours.map((details, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{details.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Link src={`tel:${details.code1}${details.phoneNumber1}`} style={styles.tableCell}>
                  {`${details.code1}${details.phoneNumber1}`}
                </Link>
              </View>
              <View style={styles.tableCol}>
                <Link src={`https://wa.me/${details.code2}${details.phoneNumber2}`} style={styles.tableCell}>
                  {`${details.code2}${details.phoneNumber2}`}
                </Link>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
        
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.workingDays')) && (
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Working days:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Working Days</Text>
            </View>
          </View>
          {profile.contactDetails.data.workingDays.map((details, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{details.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{details.days.join(', ')}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    )
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.bankDetails')) && (
    addContentToPage(
      <View style={styles.tableContainer}>
        <Text style={styles.question}>{`${questionNumber}. Bank Account Details with proof:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>File</Text>
            </View>
          </View>
          {profile.contactDetails.data.bankDetails.map((info, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{info.selectedCountry.join(', ')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{info.name}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    )
  )}
  
  {((!changedIds) || changedIds.some(change => change.id === 'contactDetails.data.canAcceptPaymentsCreditCard')) && (
    renderQuestionAnswer('Can you accept payments for bookings by a secure credit card payment link?', profile.contactDetails.data.canAcceptPaymentsCreditCard ? 'Yes' : 'No'))
  }
  
  // Packages & Tariff Upload
  addContentToPage(
    <Text style={styles.subHeader} key="packages-tariff-header">Packages & Tariff Upload</Text>
  );
  
  if (((!changedIds) || changedIds.some(change => change.id === 'packagesAndTariff.data.hasReadyMadePackages')) && profile.packagesAndTariff.data.hasReadyMadePackages && profile.packagesAndTariff.data.readyMadePackages && profile.packagesAndTariff.data.readyMadePackages.length > 0) {
    addContentToPage(
      <View style={styles.tableContainer} key="ready-made-packages">
        <Text style={styles.question}>{`${questionNumber}. Ready Made Packages:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>File/URL</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Start Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>End Date</Text>
            </View>
          </View>
          {profile.packagesAndTariff.data.readyMadePackages.map((pack, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{pack.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {pack.url ? (
                    <Link style={styles.tableCell} src={pack.url}>{pack.url}</Link>
                  ) : (
                    <Text style={styles.tableCell}>
                      {pack.fileName}
                    </Text>
                  )}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getStringBeforeT(pack.start)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getStringBeforeT(pack.end)}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  
  if (((!changedIds) || changedIds.some(change => change.id === 'packagesAndTariff.data.hasItemizedTariffSheets')) && profile.packagesAndTariff.data.hasItemizedTariffSheets && profile.packagesAndTariff.data.itemizedTariffSheets && profile.packagesAndTariff.data.itemizedTariffSheets.length > 0) {
    addContentToPage(
      <View style={styles.tableContainer} key="itemized-tariff-sheets">
        <Text style={styles.question}>{`${questionNumber}. Itemized Tariff Sheets:`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>#</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Country</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Service</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>File/URL</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Start Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>End Date</Text>
            </View>
          </View>
          {profile.packagesAndTariff.data.itemizedTariffSheets.map((pack, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{pack.country}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{pack.service}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {pack.url ? (
                    <Link style={styles.tableCell} src={pack.url}>{pack.url}</Link>
                  ) : (
                    <Text style={styles.tableCell}>
                      {pack.fileName}
                    </Text>
                  )}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getStringBeforeT(pack.start)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getStringBeforeT(pack.end)}</Text>
              </View>
            </View>
          ))}
        </View>
        {questionNumber++}
      </View>
    );
  }
  { ((!changedIds) || changedIds.some(change => change.id === 'packagesAndTariff.data.mediaLibraryLinks')) && (
  addContentToPage(
    <View style={styles.questionContainer} key="media-library">
      <Text style={styles.question}>{`${questionNumber}. Can you provide us with access to a Media Library for our marketing purposes? (google drive, BOX, etc.)`}</Text>
      <View>
        {profile.packagesAndTariff.data.mediaLibraryLinks.map((url, index) => (
          <Text key={index} style={styles.answer}>
            <Link src={url.url} style={styles.link}>{url.url}</Link>
          </Text>
        ))}
      </View>
      {questionNumber++}
    </View>
  ))}
  if (currentPageContent.length > 0) {
    addPage(currentPageContent, currentPageIndex);
  }
  return <Document>{pages}</Document>;
}
export default MyDocument;