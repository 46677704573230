import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const OfficeAddressInput = ({ selectedCountries, setSelectedCountries }) => {
    const handleofficeCityChange = (event, countryLabel) => {
        const newSelectedCountries = selectedCountries.map(country => {
            if (country.label === countryLabel) {
                return {
                    ...country,
                    city: event.target.value
                };
            }
            return country;
        });
        console.log(newSelectedCountries);
        setSelectedCountries(newSelectedCountries);
    };
    const handleofficeAddressChange = (event, countryLabel) => {
        const newSelectedCountries = selectedCountries.map(country => {       
            if (country.label === countryLabel) {
                return {
                    ...country,
                    officeAddress: event.target.value
                };
            }
            return country;
        });
        console.log(newSelectedCountries);
        setSelectedCountries(newSelectedCountries);
    }
    return (
        <>
            {selectedCountries && selectedCountries.length > 0 && selectedCountries.map((country, index) => (
                <>
                <Col md={4} key={index}>
                    <label>Local Office Address in <strong>{country.label}</strong></label>
                </Col>
                <Col md={8}>
                    <Row>
                        <Col md={12}>
                            <input
                                type='text'
                                className='form-control'
                                placeholder="Enter City"
                                value={country.city}
                                onChange={(e) => handleofficeCityChange(e, country.label)}
                                autoComplete='off'
                            />
                            <textarea
                                className='form-control mt-1'
                                placeholder="Enter Office Address"
                                value={country.officeAddress}
                                onChange={(e) => handleofficeAddressChange(e, country.label)}
                                rows={3}
                                autoComplete='off'
                            />
                        </Col>
                    </Row>
                </Col>
                </>
            ))}
        </>
    );
};

export default OfficeAddressInput;
